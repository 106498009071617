import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import { UnAuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import CookieConsentBackdrop from 'shared/components/CookiesBackdrop'
import { getReflinkDetails } from './services'
import OrderReflinkContent from './components/OrderReflinkContent'
import { FetchReflinkDetailsType } from './types'
import { BoldTypography } from './styles'
import { pl, en, de } from './locales'

export default function OrderReflink() {
   const [params] = useSearchParams()
   const [data, setData] = useState<FetchReflinkDetailsType>()
   const [image, setImage] = useState<string>()
   const token = params.get('token')
   const navigate = useNavigate()
   const [reflinkStatus, setReflinkStatus] = useState<string | null>(null)
   const { i18n, t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])
   i18n.addResourceBundle('pl-PL', 'ORDER_REFLINK', pl)
   i18n.addResourceBundle('en-EN', 'ORDER_REFLINK', en)
   i18n.addResourceBundle('de-DE', 'ORDER_REFLINK', de)

   function renderContent() {
      if (reflinkStatus === 'USED') {
         return (
            <Typography
               textAlign="center"
               sx={{ margin: '0 auto', textTransform: 'uppercase' }}
               variant="h5"
               color="red"
            >
               {t('ORDER_REFLINK:reflinkUsed')}
            </Typography>
         )
      }
      if (reflinkStatus === 'EXPIRED') {
         return (
            <Typography
               textAlign="center"
               sx={{ margin: '0 auto', textTransform: 'uppercase' }}
               variant="h5"
               color="red"
            >
               {t('ORDER_REFLINK:reflinkExpired')}
            </Typography>
         )
      }
      if (reflinkStatus === 'NOT_FOUND') {
         return (
            <Typography
               textAlign="center"
               sx={{ margin: '0 auto', textTransform: 'uppercase' }}
               variant="h5"
               color="red"
            >
               {t('ORDER_REFLINK:reflinkInvalid')}
            </Typography>
         )
      }
      if (!reflinkStatus) {
         return token && <OrderReflinkContent token={token} />
      }
      return null
   }

   useEffect(() => {
      if (token) {
         getReflinkDetails(token)
            .then((reflinkSettings) => {
               if (reflinkSettings) {
                  setData(reflinkSettings)
                  setImage(reflinkSettings.image)
               }
            })

            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  if (error.response?.data.errors[0].code === 'errors.order-reflink.reflink-not-found') {
                     setReflinkStatus('NOT_FOUND')
                  }
                  if (error.response?.data.errors[0].code === 'errors.order-reflink.reflink-expired') {
                     setReflinkStatus('EXPIRED')
                  } else if (error.response?.data.errors[0].code === 'errors.order-reflink.reflink-used') {
                     setReflinkStatus('USED')
                  }
               }
            })
      } else navigate('/')
   }, [token])

   return (
      <>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: { md: 10, xs: 3 }, py: 1 }}>
            <LanguagePopover />
         </Box>
         <Card
            sx={{
               display: 'flex',
               flexDirection: 'column',
               gap: { md: 5, xs: 4 },
               margin: { md: '1% auto' },
               pb: 10,
               height: '80vh',
               width: { md: '70%' },
               overflowY: 'scroll',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: { md: 'row', xs: 'column-reverse' },
                  justifyContent: { md: 'space-between', xs: 'normal' },
                  gap: { xs: 3 },
                  padding: 4,
                  width: '100%',
               }}
            >
               {data ? (
                  <>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                        }}
                     >
                        <BoldTypography variant="body1">{data.reflinkSettings.companyName}</BoldTypography>
                        {data.reflinkSettings.address && (
                           <BoldTypography>{data.reflinkSettings.address}</BoldTypography>
                        )}
                        {data.reflinkSettings.phoneNumber && (
                           <BoldTypography>{`Tel. ${data.reflinkSettings.phoneNumber}`}</BoldTypography>
                        )}
                     </Box>
                     {image && (
                        <img
                           style={{ alignSelf: 'center' }}
                           height="70px"
                           width="auto"
                           src={`data:image/png;base64,${image}`}
                           alt="company_logo"
                        />
                     )}
                  </>
               ) : (
                  !reflinkStatus && (
                     <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%' }}>
                        <CircularProgress color="primary" />
                     </Box>
                  )
               )}
            </Box>
            {renderContent()}
            <CookieConsentBackdrop />
         </Card>
      </>
   )
}
