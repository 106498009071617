import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useLocationsQuery } from 'shared/store/useLocations'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import InputField from 'shared/components/InputField'
import MuiPhone from 'shared/components/PhoneInput'
import { httpClient } from 'shared/lib'

interface Props {
   phone: string
   reflink: string
   notificationSentDate: Nullable<string>
   handleClose: () => void
}

export default function SmsModalContent({ phone, reflink, notificationSentDate, handleClose }: Props) {
   const { t } = useTranslation(['MAIN_CART', 'AUTHORIZED'])
   const [templateDetails, setTemplateDetails] = useState<SmsTemplateResponse>()
   const [templateMessage, setTemplateMessage] = useState('')
   const [isLoading, setIsLoading] = useState(false)
   const { data: profile } = useProfileQuery()
   const [formikInitialValues, setFormikInitialValues] = useState<{ [key: string]: string }>({})
   const [phoneNumber, setPhoneNumber] = useState('')
   const { data } = useLocationsQuery()
   const [phoneNumberError, setPhoneNumberError] = useState(false)
   const { addNotification } = useNotificationContext()
   const formik = useFormik({
      initialValues: formikInitialValues,
      onSubmit: () => {},
   })

   const { setFieldValue, setFieldTouched, values } = formik

   const populateTemplate = (message: string, variables: { [key: string]: string }) => {
      return message.replace(/<(\w+)>/g, (match, variableName) => {
         return variables[variableName] || `<${variableName}>`
      })
   }

   function handlePhoneNumber(value: string, country: string) {
      setPhoneNumber(value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   function handleSend() {
      const params = new URL(reflink!).searchParams
      const token = params.get('uuid')
      setIsLoading(true)
      if (phoneNumber) {
         httpClient
            .post('/sms/send', {
               phoneNumber,
               category: 'CLIENT_CART_REFLINK_NOTIFICATION',
               reflinkUuid: token,
               message: templateMessage !== templateDetails?.templateMessage ? templateMessage : null,
               templateValues: {
                  ...values,
                  link: values.link || reflink,
               },
            })
            .then(() => {
               addNotification(t('AUTHORIZED:sendSmsModal.messageSent'), 'success')
               handleClose()
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data.errors[0].code || 'failedSend', 'error')
               } else {
                  addNotification('failedSend', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   useEffect(() => {
      const newFormikInitials = { ...formikInitialValues }
      httpClient.get<SmsTemplateResponse>('/sms-api/templates/CLIENT_CART_REFLINK_NOTIFICATION').then((res) => {
         if (res.data) {
            setTemplateDetails(res.data)
            setTemplateMessage(res.data.templateMessage)

            res.data.templateVariables.forEach((item) => {
               newFormikInitials[item] = ''
               if (item === 'link') {
                  newFormikInitials[item] = reflink
                  setFieldValue('link', reflink)
                  setFieldTouched('link', true)
               }
               if (item === 'adres_odbioru_czesci') {
                  const activeLocation = data?.locations.find(
                     (location) => location.locationSettings.uuid === profile?.locationUuid
                  )
                  if (activeLocation) {
                     newFormikInitials[item] = activeLocation.locationSettings.orderReflinkSettings?.address || ''
                     setFieldValue(
                        'adres_odbioru_czesci',
                        activeLocation.locationSettings.orderReflinkSettings?.address || ''
                     )
                     setFieldTouched('adres_odbioru_czesci', true)
                  }
               }
            })
         }
         setFormikInitialValues(newFormikInitials)
      })
   }, [])

   useEffect(() => {
      if (phone) {
         setPhoneNumber(phone)
      }
   }, [phone])

   return (
      <>
         <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', my: 2, gap: 2 }}>
               {templateDetails && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                     {notificationSentDate && (
                        <Typography variant="h6" fontWeight="bold">
                           {t('CLIENTS_CARTS:alreadySent', {
                              date: notificationSentDate,
                           })}
                        </Typography>
                     )}
                     <FormikProvider value={formik}>
                        <MuiPhone
                           variant="outlined"
                           value={phoneNumber}
                           onChange={(value, country) => handlePhoneNumber(value, country)}
                        />
                        {templateDetails.templateVariables.length > 0 && (
                           <>
                              <Typography variant="body1">{t('AUTHORIZED:sendSmsModal:variables')}</Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                 {templateDetails.templateVariables
                                    .filter((item) => item !== 'link')
                                    .map((item) => (
                                       <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                          <InputField
                                             InputLabelProps={{
                                                shrink: !!formik.values[item],
                                             }}
                                             InputProps={{
                                                readOnly: item === 'adres_odbioru_czesci',
                                             }}
                                             name={item}
                                             label={item}
                                          />
                                       </Box>
                                    ))}
                              </Box>
                           </>
                        )}
                        <Typography variant="body1"> {t('AUTHORIZED:sendSmsModal:review')} </Typography>
                        <TextField
                           multiline
                           inputProps={{
                              maxLength: 500,
                           }}
                           rows={5}
                           onChange={(e) => setTemplateMessage(e.target.value)}
                           value={populateTemplate(templateMessage, formik.values)}
                        />
                     </FormikProvider>
                  </Box>
               )}
            </Box>
         </DialogContent>
         <DialogActions>
            <Box sx={{ display: 'flex', gap: 2 }}>
               <OutlinedButton variant="outlined" onClick={handleClose}>
                  {t('TRANSLATION:cancel')}
               </OutlinedButton>
               <ContainedButton
                  variant="contained"
                  disabled={!phoneNumber || phoneNumberError || isLoading}
                  onClick={handleSend}
               >
                  {t('TRANSLATION:send')}
               </ContainedButton>
            </Box>
         </DialogActions>
      </>
   )
}
