import React, { useState } from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'

import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { useSettingsQuery } from 'shared/store/useSettings'
import { Product } from 'shared/store/useCartSummary/types'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import ImageComponent from 'shared/components/ImageComponent'
import { ModalInfoType } from '../../../types'
import {
   QuantityHolder,
   StyledDeleteIcon,
   StyledQuantityButton,
   StyledTableCell,
   StyledTableRow,
   StyledAvailableParagraph,
   StyledCollapseTableCell,
} from '../../../styles'

interface Props {
   products: Product[]
   handleProductUpdate: (
      uuid: string,
      quantity?: number,
      includeProductInOrder?: boolean,
      netClientPrice?: number
   ) => void
   isProductUpdating: boolean
   setModalInfo: (modalInfo: ModalInfoType) => void
}

export default function MainTableMobile({ products, isProductUpdating, handleProductUpdate, setModalInfo }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { data: settings } = useSettingsQuery()
   const [collapse, setCollapse] = useState<string[]>([])

   function handleCollapseProduct(uuid: string) {
      if (collapse.includes(uuid)) {
         setCollapse(collapse.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapse]
         tempCollapseProducts.push(uuid)
         setCollapse([...tempCollapseProducts])
      }
   }

   return products.length ? (
      <>
         {products.map((product) => (
            <React.Fragment key={product.uuid}>
               <StyledTableRow onClick={() => handleCollapseProduct(product.uuid)}>
                  <StyledTableCell>
                     {product.index}
                     {product.requestedIndex &&
                        product.requestedIndex.toLowerCase() !== product.index.toLowerCase() && (
                           <span style={{ color: 'grayText', fontSize: 10 }}>{` (${product.requestedIndex})`}</span>
                        )}
                  </StyledTableCell>
                  <StyledTableCell>
                     <ImageComponent
                        filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                        producerName={product.producerName}
                        height="15px"
                     />
                  </StyledTableCell>
                  <StyledTableCell>
                     <Checkbox
                        disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                        onClick={(e) => {
                           e.stopPropagation()
                           handleProductUpdate(product.uuid, undefined, !product.includedProductInOrder)
                        }}
                        color="primary"
                        checked={product.includedProductInOrder}
                     />
                  </StyledTableCell>
                  <StyledTableCell>
                     <StyledDeleteIcon
                        onClick={(e) => {
                           e.stopPropagation()
                           setModalInfo({
                              modalType: 'DELETE',
                              uuid: product.uuid,
                              removeFromUnavailable: true,
                              index: product.index,
                           })
                        }}
                     />
                  </StyledTableCell>
               </StyledTableRow>
               <TableRow>
                  <TableCell style={{ padding: '0' }} colSpan={6}>
                     <Collapse
                        style={{ overflowY: 'scroll', maxHeight: '300px' }}
                        in={collapse.includes(product.uuid)}
                        timeout="auto"
                     >
                        <Table>
                           <TableBody>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames:wholesaler')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    <HiddenWholesalerLogo
                                       wholesalerName={product.cartWholesaleName}
                                       imgHeight="20px"
                                       imgWidth="auto"
                                    />
                                 </StyledCollapseTableCell>
                              </TableRow>
                              {settings?.userSettings.preferences.showProductNamesInCart === 'true' && (
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={3}>
                                       {t('TRANSLATION:columnNames:productName')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell colSpan={3}>
                                       {`${product.cartProductName.split(' ')[0]} ${product.cartProductName.split(' ')[1]}`}
                                    </StyledCollapseTableCell>
                                 </TableRow>
                              )}
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames:quantity')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    <QuantityHolder>
                                       <StyledQuantityButton
                                          type="button"
                                          disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                                          onClick={() =>
                                             handleProductUpdate(
                                                product.uuid,
                                                product.cartWholesaleDTO.quantity - product.quantityInSet
                                             )
                                          }
                                       >
                                          -
                                       </StyledQuantityButton>
                                       <StyledQuantityButton
                                          disabled={isProductUpdating}
                                          type="button"
                                          onClick={() =>
                                             setModalInfo({
                                                modalType: 'QUANTITY',
                                                uuid: product.uuid,
                                                quantity: product.cartWholesaleDTO.quantity,
                                             })
                                          }
                                          style={{ fontWeight: 'normal' }}
                                       >
                                          {product.cartWholesaleDTO.quantity}
                                       </StyledQuantityButton>
                                       <StyledQuantityButton
                                          type="button"
                                          disabled={
                                             isProductUpdating ||
                                             product.cartWholesaleDTO.quantity === MAX_PRODUCT_QUANTITY
                                          }
                                          onClick={() =>
                                             handleProductUpdate(
                                                product.uuid,
                                                product.cartWholesaleDTO.quantity + product.quantityInSet
                                             )
                                          }
                                       >
                                          +
                                       </StyledQuantityButton>
                                    </QuantityHolder>
                                    {product.cartWholesaleDTO.availableQuantity <= 5 &&
                                    product.cartWholesaleDTO.availableQuantity > 1 ? (
                                       <StyledAvailableParagraph>{`${t('MAIN_CART:available')} ${
                                          product.cartWholesaleDTO.availableQuantity
                                       }`}</StyledAvailableParagraph>
                                    ) : null}
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames:netPrice')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell>
                                    {product.cartWholesaleDTO.formattedNetPrice}
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>VAT</StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {product.cartWholesaleDTO.taxRate}%
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames:grossPrice')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {product.cartWholesaleDTO.formattedGrossValue}
                                 </StyledCollapseTableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </Collapse>
                  </TableCell>
               </TableRow>
            </React.Fragment>
         ))}
      </>
   ) : (
      <TableRow>
         <TableCell colSpan={10}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  opacity: 0.6,
                  fontWeight: 'bold',
                  color: 'grayText',
               }}
            >
               {t('MAIN_CART:noProducts')}
            </Box>
         </TableCell>
      </TableRow>
   )
}
