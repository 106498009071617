import { Backdrop, Card, Typography, Box, styled } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import OutlinedButton from '../Buttons/OutlinedButton'
import ContainedButton from '../Buttons/ContainedButton'

const StyledLink = styled('a')(({ theme }) => ({
   textDecoration: 'none',
   color: 'black',
   fontWeight: 'bold',
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
   },
}))

export default function CookieConsentBackdrop() {
   const [consent, setConsent] = useState(localStorage.getItem('approveCookies'))
   const { t } = useTranslation('TRANSLATION')
   useEffect(() => {
      const handleStorageChange = () => {
         setConsent(localStorage.getItem('approveCookies'))
      }

      window.addEventListener('storage', handleStorageChange)

      return () => {
         window.removeEventListener('storage', handleStorageChange)
      }
   }, [])

   const handleConsent = (value: string | null) => {
      if (value === null) {
         localStorage.removeItem('approveCookies')
      } else {
         localStorage.setItem('approveCookies', value)
      }
      setConsent(value)
   }

   if (!consent) {
      return (
         <Backdrop sx={{ backdropFilter: 'blur(5px)', zIndex: 1 }} open>
            <Card sx={{ p: '16px 24px', display: 'flex', flexDirection: 'column', gap: '16px', textAlign: 'center' }}>
               <Typography variant="h5">{t('cookiesBackdrop.title')}</Typography>
               <Typography variant="h6">{t('cookiesBackdrop.rejectChange')}</Typography>
               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                  <StyledLink href="https://motorro.pl/regulamin/" target="_blank">
                     {t('cookiesBackdrop.termsOfUse')}
                  </StyledLink>
                  <StyledLink href="https://motorro.pl/polityka-prywatnosci/" target="_blank">
                     {t('cookiesBackdrop.privacyPolicy')}
                  </StyledLink>
               </Box>
               <Box sx={{ display: 'flex', gap: '8px', width: '100%', justifyContent: 'center' }}>
                  <OutlinedButton variant="outlined" onClick={() => handleConsent('false')}>
                     {t('cookiesBackdrop.reject')}
                  </OutlinedButton>
                  <ContainedButton variant="contained" onClick={() => handleConsent('true')}>
                     {t('cookiesBackdrop.accept')}
                  </ContainedButton>
               </Box>
            </Card>
         </Backdrop>
      )
   }

   if (consent === 'false') {
      return (
         <Backdrop sx={{ backdropFilter: 'blur(5px)', zIndex: 1 }} open>
            <Card sx={{ p: '16px 24px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
               <Typography variant="h6">{t('cookiesBackdrop.deniedTitle')}</Typography>
               <Box sx={{ display: 'flex', gap: '8px', width: '100%', justifyContent: 'center' }}>
                  <ContainedButton variant="contained" onClick={() => handleConsent(null)}>
                     {t('cookiesBackdrop.changeConsent')}
                  </ContainedButton>
               </Box>
            </Card>
         </Backdrop>
      )
   }

   return null
}
