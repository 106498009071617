import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { createReflink, deleteReflink, editReflink, fetchReflinks } from './services'
import { ReflinksResult, CreateReflinkPayload, EditReflinkPayload } from './types'

export default function useClientReflinksQuery(): UseQueryResult<ReflinksResult> {
   return useQuery('clientReflinks', fetchReflinks)
}

export function useAddReflinkMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, CreateReflinkPayload, unknown>(
      (payload) => createReflink(payload),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('clientReflinks')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useEditReflinkMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      AxiosError<ErrorType> | Error,
      { payload: EditReflinkPayload; reflinkUuid: string },
      unknown
   >(({ payload, reflinkUuid }) => editReflink(payload, reflinkUuid), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('clientReflinks')
            addNotification('successSave', 'success')
         }
      },
      onError: (error) => {
         if (error) {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         }
      },
   })
}

export function useDeleteReflinkMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, string, unknown>(
      (reflinkUuid) => deleteReflink(reflinkUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('clientReflinks')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
