import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableBody from '@mui/material/TableBody'
import Collapse from '@mui/material/Collapse'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import { Table } from '@mui/material'
import Box from '@mui/material/Box'

import { useNotificationContext } from 'shared/context/Notifications'
import { Product } from 'shared/store/useCartSummary/types'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import ImageComponent from 'shared/components/ImageComponent'
import {
   QuantityHolder,
   StyledDeleteIcon,
   StyledQuantityButton,
   StyledTableCell,
   StyledTableRow,
   StyledCollapseTableCell,
   StyledInput,
} from '../../../styles'
import { ClientViewPricesType, ModalInfoType } from '../../../types'
import getProductName from '../../../helpers/getProductName'

interface Props {
   products: Product[]
   setModalInfo: (modalInfo: ModalInfoType) => void
   isProductUpdating: boolean
   handleProductUpdate: (
      uuid: string,
      quantity?: number,
      includeProductInOrder?: boolean,
      netClientPrice?: number
   ) => void
}

export default function TableForClientMobile({
   products,
   setModalInfo,
   handleProductUpdate,
   isProductUpdating,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { addNotification } = useNotificationContext()
   const [collapse, setCollapse] = useState<string[]>([])
   const [clientPrices, setClientPrices] = useState<ClientViewPricesType>([])

   function handleCollapseProduct(uuid: string) {
      if (collapse.includes(uuid)) {
         setCollapse(collapse.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapse]
         tempCollapseProducts.push(uuid)
         setCollapse([...tempCollapseProducts])
      }
   }

   function changeClientPrice(clientPrice: number, uuid: string, productPrice: number, taxRate: number) {
      const tempClientPrice = clientPrice <= productPrice ? productPrice + 0.01 : clientPrice
      if (clientPrice <= productPrice + 0.01) {
         addNotification(t('MAIN_CART:clientPriceError'), 'error')
         changeStateClientPrice(productPrice, uuid, taxRate)
      } else {
         handleProductUpdate(uuid, undefined, undefined, tempClientPrice)
      }
   }

   function changeStateClientPrice(newClientPrice: number, uuid: string, taxRate: number) {
      setClientPrices((prevState) => {
         const newState = prevState.map((product) => {
            if (product.uuid === uuid) {
               return {
                  ...product,
                  clientPriceNet: Number(newClientPrice),
                  clientPriceGross: Number((newClientPrice * (1 + taxRate / 100)).toFixed(2)),
               }
            }
            return product
         })

         return newState
      })
   }

   useEffect(() => {
      const tempClientPrices: ClientViewPricesType = []
      products.forEach((product) => {
         tempClientPrices.push({
            uuid: product.uuid,
            clientPriceNet: Number(product.cartWholesaleDTO.clientNetPrice),
            clientPriceGross: Number((product.cartWholesaleDTO.clientNetPrice * 1.23).toFixed(2)),
         })
      })
      setClientPrices([...tempClientPrices])
   }, [])

   return products.length ? (
      <>
         {products
            .filter((item) => item.status !== 'NOT_AVAILABLE')
            .map((product) => (
               <React.Fragment key={product.uuid}>
                  <StyledTableRow onClick={() => handleCollapseProduct(product.uuid)}>
                     <StyledTableCell>{getProductName(product)}</StyledTableCell>
                     <StyledTableCell>
                        <ImageComponent
                           filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                           producerName={product.producerName}
                           height="15px"
                        />
                     </StyledTableCell>
                     <StyledTableCell>
                        <Checkbox
                           disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                           onClick={(e) => {
                              e.stopPropagation()
                              handleProductUpdate(product.uuid, undefined, !product.includedProductInOrder)
                           }}
                           color="primary"
                           checked={product.includedProductInOrder}
                        />
                     </StyledTableCell>
                     <StyledTableCell>
                        <StyledDeleteIcon
                           onClick={(e) => {
                              e.stopPropagation()
                              setModalInfo({
                                 modalType: 'DELETE',
                                 uuid: product.uuid,
                                 removeFromUnavailable: true,
                                 index: product.index,
                              })
                           }}
                        />
                     </StyledTableCell>
                  </StyledTableRow>
                  <TableCell style={{ padding: '0' }} colSpan={6}>
                     <Collapse
                        style={{ overflowY: 'scroll', maxHeight: '300px' }}
                        in={collapse.includes(product.uuid)}
                        timeout="auto"
                     >
                        <Table>
                           <TableBody>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames.quantity')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    <QuantityHolder>
                                       <StyledQuantityButton
                                          disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                                          onClick={() =>
                                             handleProductUpdate(
                                                product.uuid,
                                                product.cartWholesaleDTO.quantity - product.quantityInSet
                                             )
                                          }
                                       >
                                          -
                                       </StyledQuantityButton>
                                       <StyledQuantityButton
                                          disabled={isProductUpdating}
                                          type="button"
                                          onClick={() =>
                                             setModalInfo({
                                                modalType: 'QUANTITY',
                                                uuid: product.uuid,
                                                index: product.index,
                                             })
                                          }
                                          style={{ fontWeight: 'normal' }}
                                       >
                                          {product.cartWholesaleDTO.quantity}
                                       </StyledQuantityButton>
                                       <StyledQuantityButton
                                          disabled={
                                             isProductUpdating ||
                                             product.cartWholesaleDTO.quantity === MAX_PRODUCT_QUANTITY
                                          }
                                          onClick={() =>
                                             handleProductUpdate(
                                                product.uuid,
                                                product.cartWholesaleDTO.quantity + product.quantityInSet
                                             )
                                          }
                                       >
                                          +
                                       </StyledQuantityButton>
                                    </QuantityHolder>
                                    {product.cartWholesaleDTO.availableQuantity <= 5 &&
                                    product.cartWholesaleDTO.availableQuantity > 1 ? (
                                       <p
                                          style={{
                                             marginBottom: 0,
                                             marginTop: 0,
                                             opacity: 0.5,
                                             color: 'GrayText',
                                             fontSize: 12,
                                             fontWeight: 'lighter',
                                          }}
                                       >{`${t('MAIN_CART:available')} ${
                                          product.cartWholesaleDTO.availableQuantity
                                       }`}</p>
                                    ) : null}
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames.netPrice')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    <StyledInput
                                       onBlur={(e) =>
                                          changeClientPrice(
                                             Number(e.target.value),
                                             product.uuid,
                                             product.cartWholesaleDTO.netPrice,
                                             product.cartWholesaleDTO.taxRate
                                          )
                                       }
                                       onFocus={(e) => e.target.select()}
                                       disabled={isProductUpdating}
                                       type="Number"
                                       onChange={(e) =>
                                          changeStateClientPrice(
                                             Number(e.target.value),
                                             product.uuid,
                                             product.cartWholesaleDTO.taxRate
                                          )
                                       }
                                       value={clientPrices.find((item) => item.uuid === product.uuid)?.clientPriceNet}
                                    />
                                    {product.cartWholesaleDTO.currency}
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>VAT</StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {product.cartWholesaleDTO.taxRate}%
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames.grossPrice')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    <StyledInput
                                       onBlur={(e) =>
                                          changeClientPrice(
                                             Number(e.target.value) / (1 + product.cartWholesaleDTO.taxRate / 100),
                                             product.uuid,
                                             product.cartWholesaleDTO.netPrice,
                                             product.cartWholesaleDTO.taxRate
                                          )
                                       }
                                       type="Number"
                                       onFocus={(e) => e.target.select()}
                                       onChange={(e) =>
                                          changeStateClientPrice(
                                             Number(e.target.value) / (1 + product.cartWholesaleDTO.taxRate / 100),
                                             product.uuid,
                                             product.cartWholesaleDTO.taxRate
                                          )
                                       }
                                       disabled={isProductUpdating}
                                       value={clientPrices.find((item) => item.uuid === product.uuid)?.clientPriceGross}
                                    />
                                    {product.cartWholesaleDTO.currency}
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames.grossValue')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {product.cartWholesaleDTO.formattedGrossValue}
                                 </StyledCollapseTableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </Collapse>
                  </TableCell>
               </React.Fragment>
            ))}
      </>
   ) : (
      <TableRow>
         <TableCell colSpan={10}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  opacity: 0.6,
                  fontWeight: 'bold',
                  color: 'grayText',
               }}
            >
               {t('MAIN_CART:noProducts')}
            </Box>
         </TableCell>
      </TableRow>
   )
}
