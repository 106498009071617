import React, { useEffect, useState } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import TableFooter from '@mui/material/TableFooter'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'

import { StyledContentTableCell, StyledTable, StyledTableCell } from 'shared/components/StyledTableComponents'
import { sortDirectionType } from 'views/Authorized/views/ProductsList/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import ImageComponent from 'shared/components/ImageComponent'
import { SearchResponseType, ReflinkDataType, SearchProductType, AddProductPayload } from '../../types'
import { ProductTableRow, StyledAvailabilityDot, StyledSpan } from '../../styles'
import { addProductService } from '../../services/addProductService'

interface Props {
   searchPhrase: string
   data?: SearchResponseType
   open: boolean
   updateData: (data: ReflinkDataType) => void
   handleClose: () => void
}

const columnNames = [
   { name: 'index', translateLabel: 'partIndex' },
   { name: 'name', translateLabel: 'productName' },
   { name: 'producer', translateLabel: 'producer' },
   { name: 'availability', translateLabel: 'availability' },
]

export default function SearchModal({ searchPhrase, data, open, handleClose, updateData }: Props) {
   const [sortBy, setSortBy] = useState<keyof SearchProductType>()
   const { addNotification } = useNotificationContext()
   const [addToCartDetails, setAddToCartDetails] = useState<AddProductPayload>({
      quantity: 1,
      uuid: '',
      index: '',
   })
   const { t } = useTranslation(['CLIENT_CART', 'TRANSLATION'])
   const [sortDirection, setSortDirection] = useState<sortDirectionType>('asc')
   const [activePage, setActivePage] = useState(0)
   const [isAdding, setIsAdding] = useState(false)
   const [itemsPerPage, setItemsPerPage] = useState(10)

   function foundResultsString(resultsCount: number) {
      if (resultsCount === 0) return 'notFound'
      return ''
   }

   function handleItemsPerPage(event: SelectChangeEvent<number>) {
      setActivePage(0)
      window.localStorage.setItem('itemsPerPage', event.target.value.toString())
      setItemsPerPage(event.target.value as number)
   }

   function handleSortingChange(sortName: keyof SearchProductType) {
      setSortBy(sortName)
      if (sortDirection === 'asc') {
         setSortDirection('desc')
      } else {
         setSortDirection('asc')
      }
   }

   function sortingType(a: string | number | boolean, b: string | number | boolean) {
      switch (sortDirection) {
         case 'desc':
            return a > b ? -1 : 1
         case 'asc':
            return a > b ? 1 : -1
         default:
            return 0
      }
   }
   function returnProductName(productName: string, productIndex: string) {
      if (productName) {
         if (productName.length > 100) {
            return `${productName.slice(0, 100)}...`
         }
         return productName
      }
      return productIndex
   }

   function returnAvailabilityDot(availability: number) {
      switch (true) {
         case availability === 0:
            return <StyledAvailabilityDot color="red" />
         case availability === 1:
            return <StyledAvailabilityDot color="orange" />
         case availability === -1:
            return <StyledAvailabilityDot color="gray" />
         case availability > 1:
            return <StyledAvailabilityDot color="green" />
         default:
            return null
      }
   }

   function handleAddProduct() {
      const uuid = window.localStorage.getItem('uuid')
      const token = window.localStorage.getItem('reflink-verify-token')
      if (uuid && token) {
         setIsAdding(true)
         addProductService(uuid, token, addToCartDetails.uuid, addToCartDetails.quantity, addToCartDetails.index)
            .then((res) => {
               addNotification(t('CLIENT_CART:searchModal.productAdded'), 'success')
               if (res.data) {
                  updateData(res.data)
               }
               handleClose()
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  if (error.response?.data.errors[0].code === 'errors.authentication.reflink-code-expired') {
                     addNotification(t('CLIENT_CART:expired'), 'error')
                     window.localStorage.removeItem('reflink-verify-token')
                  } else {
                     addNotification(
                        error.response?.data?.errors[0].code || t('CLIENT_CART:searchModal.productAddFailed'),
                        'error'
                     )
                  }
               } else {
                  addNotification(t('CLIENT_CART:searchModal.productAddFailed'), 'error')
               }
            })
            .finally(() => setIsAdding(false))
      }
   }

   function renderContent() {
      if (addToCartDetails.uuid) {
         return (
            <Box>
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                  <Typography variant="h6" color="primary">
                     {t('TRANSLATION:columnNames.pickQuantity')}
                  </Typography>
                  <Input
                     disabled={isAdding}
                     fullWidth
                     type="Number"
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                           handleAddProduct()
                        }
                     }}
                     value={addToCartDetails.quantity}
                     onChange={(e) => setAddToCartDetails({ ...addToCartDetails, quantity: Number(e.target.value) })}
                     inputProps={{ min: 1, max: 600, sx: { textAlign: 'center' } }}
                  />
               </Box>
            </Box>
         )
      }
      if (data) {
         return (
            <Box>
               <div
                  style={{
                     margin: '0 auto',
                     display: 'flex',
                     flexDirection: data.resultsCount >= 250 || data.resultsCount === 0 ? 'column' : 'row',
                     gap: 5,
                  }}
               >
                  <Typography variant="body1" color="text.secondary">
                     {t('CLIENT_CART:searchedPhrase')}
                     <StyledSpan>{searchPhrase}</StyledSpan>
                  </Typography>
                  {data.resultsCount === 0 || data.resultsCount === 250 ? (
                     <Typography variant="h6" sx={{ color: 'red', textTransform: 'upperCase', margin: '0 auto' }}>
                        {t(`CLIENT_CART:${foundResultsString(data.resultsCount)}`)}
                     </Typography>
                  ) : (
                     <Typography variant="body1" sx={{ color: 'grayText' }}>
                        {t('CLIENT_CART:found')} <StyledSpan> {data ? data.resultsCount : 0} </StyledSpan>
                     </Typography>
                  )}
               </div>
               {data.products.length > 0 && (
                  <StyledTable>
                     <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                        <TableRow>
                           {columnNames.map((column) => (
                              <StyledTableCell key={column.name}>
                                 <TableSortLabel
                                    onClick={() => handleSortingChange(column.name as keyof SearchProductType)}
                                    active={sortBy === column.name}
                                    direction={sortDirection}
                                 >
                                    {t(`TRANSLATION:columnNames.${column.translateLabel}`)}
                                 </TableSortLabel>
                              </StyledTableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {data.products
                           .sort((a, b) => (sortBy ? sortingType(a[sortBy], b[sortBy]) : 0))
                           .slice(itemsPerPage * activePage, itemsPerPage * activePage + itemsPerPage)
                           .map((product) => (
                              <ProductTableRow
                                 sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
                                 onClick={() =>
                                    setAddToCartDetails({ quantity: 1, index: product.index, uuid: product.uuid })
                                 }
                                 key={product.uuid}
                              >
                                 <StyledContentTableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    {product.index.length > 30 ? `${product.index.slice(0, 30)}...` : product.index}
                                 </StyledContentTableCell>
                                 <StyledContentTableCell sx={{ textAlign: 'center', color: 'grayText', width: '50%' }}>
                                    {returnProductName(product.name, product.index)}
                                 </StyledContentTableCell>
                                 <StyledContentTableCell>
                                    <Tooltip title={product.producer}>
                                       <ImageComponent
                                          filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                                          producerName={product.producer}
                                       />
                                    </Tooltip>
                                 </StyledContentTableCell>
                                 <StyledContentTableCell>
                                    {returnAvailabilityDot(product.availability)}
                                 </StyledContentTableCell>
                              </ProductTableRow>
                           ))}
                     </TableBody>
                     <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                        <TableRow>
                           <StyledContentTableCell colSpan={2} />
                           <StyledContentTableCell colSpan={2} sx={{ textAlign: 'right', paddingRight: 7 }}>
                              {t('CLIENT_CART:itemsPerPage')}
                              <Select
                                 sx={{ mx: 1, color: 'grayText', fontSize: 14 }}
                                 variant="standard"
                                 disabled={data.products.length === 0}
                                 value={itemsPerPage}
                                 onChange={(e) => handleItemsPerPage(e)}
                              >
                                 <MenuItem value={10}> 10 </MenuItem>
                                 <MenuItem value={25}> 25 </MenuItem>
                                 <MenuItem value={50}> 50 </MenuItem>
                              </Select>
                              {t('CLIENT_CART:displayed', {
                                 displayed: `${itemsPerPage * activePage + 1} - ${
                                    itemsPerPage * activePage + itemsPerPage > data.products.length
                                       ? data.products.length
                                       : itemsPerPage * activePage + itemsPerPage
                                 }`,
                                 all: data.products.length,
                              })}
                              <IconButton
                                 disabled={data.products.length === 0 || activePage === 0}
                                 onClick={() => setActivePage((page) => page - 1)}
                              >
                                 <ChevronLeftIcon />
                              </IconButton>
                              <IconButton
                                 disabled={
                                    data
                                       ? data.products.length === 0 ||
                                         activePage === Math.ceil(data.products.length / itemsPerPage) - 1
                                       : false
                                 }
                                 onClick={() => setActivePage((page) => page + 1)}
                              >
                                 <ChevronRightIcon />
                              </IconButton>
                           </StyledContentTableCell>
                        </TableRow>
                     </TableFooter>
                  </StyledTable>
               )}
            </Box>
         )
      }
      return (
         <Box sx={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress />
         </Box>
      )
   }

   useEffect(() => {
      setAddToCartDetails({
         quantity: 1,
         uuid: '',
         index: '',
      })
      setActivePage(0)
   }, [open])

   return (
      <Dialog maxWidth="xl" fullWidth={!addToCartDetails.uuid} open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography variant="h4" color="primary" textAlign="center">
               {t('CLIENT_CART:searchModal.searchResults')}
            </Typography>
         </DialogTitle>
         <DialogContent>{renderContent()}</DialogContent>
         <DialogActions>
            {addToCartDetails.uuid ? (
               <Box sx={{ display: 'flex', gap: 1, margin: '0 auto' }}>
                  <OutlinedButton
                     disabled={isAdding}
                     variant="outlined"
                     onClick={() => setAddToCartDetails({ quantity: 1, uuid: '', index: '' })}
                  >
                     {t('TRANSLATION:cancel')}
                  </OutlinedButton>
                  <ContainedButton disabled={isAdding} variant="contained" onClick={handleAddProduct}>
                     {t('TRANSLATION:save')}
                  </ContainedButton>
               </Box>
            ) : (
               <ContainedButton disabled={isAdding} sx={{ margin: '0 auto' }} variant="contained" onClick={handleClose}>
                  {t('CLIENT_CART:close')}
               </ContainedButton>
            )}
         </DialogActions>
      </Dialog>
   )
}
