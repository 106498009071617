import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'

import { LocationDetailsType, MarkupThresholdType } from 'shared/store/useLocations/types'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useProfileQuery } from 'shared/store/useProfile'
import SelectField from 'shared/components/SelectField'

import { InfinityThresholdType } from '../../types'

interface Props {
   infinityThreshold: InfinityThresholdType
   setInfinityThreshold: (values: InfinityThresholdType) => void
}

export default function MarkupSettings({ infinityThreshold, setInfinityThreshold }: Props) {
   const { data } = useProfileQuery()
   const { values, setFieldValue } = useFormikContext<LocationDetailsType>()
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const [thresholds, setThresholds] = useState<MarkupThresholdType[]>([])
   const textFieldRef = useRef<HTMLInputElement>(null)
   const priceTypeOptions = [
      { name: t('LOCATIONS:markupSettings.grossLabel'), value: 'GROSS' },
      { name: t('LOCATIONS:markupSettings.netLabel'), value: 'NET' },
   ]

   function handleChange(index: number, field: string, value: number) {
      const newThresholds = thresholds.map((item, i) => {
         if (i === index) {
            return { ...item, [field]: value }
         }
         return item
      })

      setThresholds(newThresholds)
   }

   function addThreshold() {
      const lastElement = thresholds[thresholds.length - 1]
      if (lastElement) {
         const newThresholds = [...thresholds]

         if (lastElement.end) {
            newThresholds.push({
               start: Number((lastElement.end + 0.01).toFixed(2)),
               end: Number((lastElement.end + 0.02).toFixed(2)),
               multiplier: 1,
               addend: 1,
            })
         }
         setThresholds(newThresholds)
      } else {
         setThresholds([{ start: 0, end: null, multiplier: 1, addend: 1 }])
      }
   }

   function removeLastItem() {
      const newThresholds = thresholds.slice(0, -1)
      setThresholds(newThresholds)
   }

   function getLastThresholdStartValue() {
      if (thresholds.length > 0) {
         if (thresholds[thresholds.length - 1]) {
            const endValue = thresholds[thresholds.length - 1].end
            if (endValue !== null) {
               return (endValue + 0.01).toFixed(2)
            }
            return ''
         }
      }
      return 0
   }

   useEffect(() => {
      if (values) {
         setThresholds(values.markupSettings.thresholds)
      }
   }, [values.locationSettings.uuid])

   useEffect(() => {
      setFieldValue('markupSettings.thresholds', [...thresholds])
   }, [thresholds])

   useEffect(() => {
      if (textFieldRef.current) {
         textFieldRef.current.focus()
      }
   }, [thresholds.length])

   return (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', py: 3 }}>
         <Typography sx={{ pb: 2 }} variant="h6" color="primary">
            {t('LOCATIONS:settingsLabel')}
         </Typography>
         <SelectField
            options={priceTypeOptions}
            name="markupSettings.priceType"
            label={t('LOCATIONS:markupSettings.typeOfPriceLabel')}
         />
         <Box>
            <Box sx={{ maxHeight: 450, overflowY: 'scroll' }}>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableCell sx={{ textAlign: 'center' }}>
                           {t('LOCATIONS:markupSettings.start', {
                              priceType: t(`TRANSLATION:${data?.priceType}`),
                           })}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                           {t('LOCATIONS:markupSettings.end', {
                              priceType: t(`TRANSLATION:${data?.priceType}`),
                           })}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{t('LOCATIONS:markupSettings.multiplier')}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}> {t('LOCATIONS:markupSettings.addend')}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }} />
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {thresholds.length > 0 &&
                        thresholds.map((item, index) => {
                           const isLastItem = index === thresholds.length - 1
                           return (
                              // eslint-disable-next-line react/no-array-index-key
                              <TableRow key={index}>
                                 <TableCell
                                    sx={{
                                       textAlign: 'center',
                                       '& .MuiTableCell-root': {
                                          px: 3,
                                          py: 0,
                                       },
                                    }}
                                 >
                                    <TextField
                                       sx={{
                                          '& .MuiInputBase-input': {
                                             py: 1,
                                             width: 100,
                                          },
                                       }}
                                       type="Number"
                                       variant="outlined"
                                       disabled
                                       value={item.start}
                                       inputProps={{ min: item.start, step: 0.01 }}
                                    />
                                 </TableCell>
                                 <TableCell sx={{ textAlign: 'center' }}>
                                    <TextField
                                       sx={{
                                          '& .MuiInputBase-input': {
                                             py: 1,
                                             width: 100,
                                          },
                                       }}
                                       type="Number"
                                       inputRef={textFieldRef}
                                       onFocus={(e) => e.target.select()}
                                       variant="outlined"
                                       error={item.end ? item.start > item.end : false}
                                       value={item.end}
                                       onChange={(e) => {
                                          handleChange(index, 'end', Number(e.target.value))
                                       }}
                                       inputProps={{ min: item.start + 0.01, step: 0.01 }}
                                       disabled={!isLastItem}
                                    />
                                 </TableCell>
                                 <TableCell sx={{ textAlign: 'center' }}>
                                    <TextField
                                       sx={{
                                          '& .MuiInputBase-input': {
                                             py: 1,
                                             width: 100,
                                          },
                                       }}
                                       type="Number"
                                       onFocus={(e) => e.target.select()}
                                       variant="outlined"
                                       value={item.multiplier}
                                       onChange={(e) => handleChange(index, 'multiplier', Number(e.target.value))}
                                       inputProps={{ min: 1, step: 0.1 }}
                                    />
                                 </TableCell>
                                 <TableCell sx={{ textAlign: 'center' }}>
                                    <TextField
                                       sx={{
                                          '& .MuiInputBase-input': {
                                             py: 1,
                                             width: 100,
                                          },
                                       }}
                                       type="Number"
                                       onFocus={(e) => e.target.select()}
                                       variant="outlined"
                                       value={item.addend}
                                       onChange={(e) => handleChange(index, 'addend', Number(e.target.value))}
                                       inputProps={{
                                          min: 1,
                                          step: 1,
                                       }}
                                    />
                                 </TableCell>
                                 <TableCell sx={{ textAlign: 'center' }}>
                                    {isLastItem && (
                                       <Button onClick={removeLastItem} variant="outlined" color="error">
                                          {t('TRANSLATION:delete')}
                                       </Button>
                                    )}
                                 </TableCell>
                              </TableRow>
                           )
                        })}
                     <TableRow>
                        <TableCell
                           sx={{
                              textAlign: 'center',
                           }}
                        >
                           <TextField
                              sx={{
                                 '& .MuiInputBase-input': {
                                    py: 1,
                                    width: 100,
                                 },
                              }}
                              variant="outlined"
                              disabled
                              value={getLastThresholdStartValue()}
                           />
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                           <TextField
                              variant="outlined"
                              sx={{
                                 '& .MuiInputBase-input': {
                                    textAlign: 'center',
                                    fontSize: '24px',
                                    paddingY: 0,
                                    width: 100,
                                 },
                              }}
                              value="∞"
                              disabled
                           />
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                           <TextField
                              sx={{
                                 '& .MuiInputBase-input': {
                                    py: 1,
                                    width: 100,
                                 },
                              }}
                              type="Number"
                              onFocus={(e) => e.target.select()}
                              variant="outlined"
                              value={infinityThreshold.multiplier}
                              onChange={(e) =>
                                 setInfinityThreshold({
                                    ...infinityThreshold,
                                    multiplier: Number(e.target.value),
                                 })
                              }
                              inputProps={{ min: 1, step: 0.1 }}
                           />
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                           <TextField
                              sx={{
                                 '& .MuiInputBase-input': {
                                    py: 1,
                                    width: 100,
                                 },
                              }}
                              type="Number"
                              onFocus={(e) => e.target.select()}
                              variant="outlined"
                              value={infinityThreshold.addend}
                              onChange={(e) =>
                                 setInfinityThreshold({
                                    ...infinityThreshold,
                                    addend: Number(e.target.value),
                                 })
                              }
                              inputProps={{ min: 0, step: 1 }}
                           />
                        </TableCell>
                        <TableCell />
                     </TableRow>
                  </TableBody>
               </Table>
            </Box>
            {thresholds.find((item) => item.end && item.start > item.end) && (
               <Typography variant="body1" color="error">
                  {t('LOCATIONS:markupSettings.badEndValue', {
                     thresholdIndex: thresholds.findIndex((item) => item.end && item.start > item.end) + 1,
                  })}
               </Typography>
            )}
            {thresholds.length === 20 && (
               <Typography variant="body1" color="error">
                  {t('LOCATIONS:markupSettings.maxCountOfThresholds')}
               </Typography>
            )}
            {(thresholds.find((item) => item.multiplier < 1) || infinityThreshold.multiplier < 1) && (
               <Typography variant="body1" color="error">
                  {t('LOCATIONS:markupSettings.badMultiplierValue')}
               </Typography>
            )}
            {thresholds.length > 0 && thresholds[thresholds.length - 1].end === null && (
               <Typography variant="body1" color="error">
                  {t('LOCATIONS:markupSettings.tooltip')}
               </Typography>
            )}
            <Box sx={{ textAlign: 'end', py: 1 }}>
               <OutlinedButton
                  disabled={
                     (thresholds.length > 0 && !thresholds[thresholds.length - 1].end) ||
                     thresholds.length === 20 ||
                     (thresholds.length > 0 && !!thresholds.find((item) => item.end && item.start > item.end)) ||
                     (thresholds.length > 0 && !!thresholds.find((item) => item.multiplier < 1))
                  }
                  variant="outlined"
                  onClick={addThreshold}
               >
                  {t('LOCATIONS:markupSettings.addThreshold')}
               </OutlinedButton>
            </Box>
            <Typography variant="body1"> {t('LOCATIONS:markupSettings.firstTip')}</Typography>
            <Typography variant="body1"> {t('LOCATIONS:markupSettings.secondTip')}</Typography>
         </Box>
      </Box>
   )
}
