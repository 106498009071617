import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import React from 'react'

import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { useSettingsQuery } from 'shared/store/useSettings'
import { Product } from 'shared/store/useCartSummary/types'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import { useProfileQuery } from 'shared/store/useProfile'
import ImageComponent from 'shared/components/ImageComponent'
import { ModalInfoType } from '../../../types'
import {
   QuantityHolder,
   StyledDeleteIcon,
   StyledQuantityButton,
   StyledTableRow,
   StyledCopyIcon,
   StyledAvailableParagraph,
   StyledTableCell,
} from '../../../styles'

interface Props {
   products: Product[]
   handleProductUpdate: (
      uuid: string,
      quantity?: number,
      includeProductInOrder?: boolean,
      netClientPrice?: number
   ) => void
   isProductUpdating: boolean
   setModalInfo: (modalInfo: ModalInfoType) => void
}

export default function MainTableDesktop({ products, handleProductUpdate, isProductUpdating, setModalInfo }: Props) {
   const { t } = useTranslation('MAIN_CART')
   const { data: settings } = useSettingsQuery()
   const { data } = useProfileQuery()

   return products.length ? (
      <>
         {products.map((product) => (
            <StyledTableRow key={product.uuid}>
               <Tooltip
                  disableHoverListener={settings?.userSettings.preferences.showProductNamesInCart === 'true'}
                  title={product.cartProductName ? product.cartProductName : product.index}
               >
                  <StyledTableCell>
                     {product.index.toLowerCase()}
                     {product.requestedIndex &&
                        product.requestedIndex.toLowerCase() !== product.index.toLowerCase() && (
                           <span style={{ color: 'grayText', fontSize: 10 }}>{` (${product.requestedIndex})`}</span>
                        )}
                     <CopyToClipboard text={product.index}>
                        <StyledCopyIcon
                           onClick={(e) => {
                              e.stopPropagation()
                           }}
                        />
                     </CopyToClipboard>
                  </StyledTableCell>
               </Tooltip>
               {settings?.userSettings.preferences.showProductNamesInCart === 'true' && (
                  <Tooltip title={product.cartProductName ?? product.index}>
                     <StyledTableCell>
                        {product.cartProductName
                           ? `${product.cartProductName.split(' ')[0]} ${product.cartProductName.split(' ')[1]}`
                           : product.index}
                     </StyledTableCell>
                  </Tooltip>
               )}
               <StyledTableCell>
                  <ImageComponent
                     height="15px"
                     width="auto"
                     producerName={product.producerName}
                     filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                  />
               </StyledTableCell>
               <StyledTableCell>
                  <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                     <HiddenWholesalerLogo
                        wholesalerName={product.cartWholesaleName}
                        imgHeight="20px"
                        imgWidth="auto"
                     />

                     {product.requirePrepayment && (
                        <Tooltip title={t('TRANSLATION:requirePrepayment')}>
                           <AttachMoneyIcon color="success" />
                        </Tooltip>
                     )}
                  </Box>
               </StyledTableCell>
               <StyledTableCell>
                  <QuantityHolder>
                     <StyledQuantityButton
                        disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                        onClick={() =>
                           handleProductUpdate(product.uuid, product.cartWholesaleDTO.quantity - product.quantityInSet)
                        }
                     >
                        -
                     </StyledQuantityButton>
                     <StyledQuantityButton
                        disabled={isProductUpdating}
                        type="button"
                        onClick={() =>
                           setModalInfo({
                              modalType: 'QUANTITY',
                              uuid: product.uuid,
                              quantity: product.cartWholesaleDTO.quantity,
                           })
                        }
                        style={{ fontWeight: 'normal' }}
                     >
                        {product.cartWholesaleDTO.quantity}
                     </StyledQuantityButton>
                     <StyledQuantityButton
                        disabled={isProductUpdating || product.cartWholesaleDTO.quantity === MAX_PRODUCT_QUANTITY}
                        onClick={() =>
                           handleProductUpdate(product.uuid, product.cartWholesaleDTO.quantity + product.quantityInSet)
                        }
                     >
                        +
                     </StyledQuantityButton>
                  </QuantityHolder>
                  {product.cartWholesaleDTO.availableQuantity <= 5 && product.cartWholesaleDTO.availableQuantity > 1 ? (
                     <StyledAvailableParagraph>{`${t('MAIN_CART:available')} ${
                        product.cartWholesaleDTO.availableQuantity
                     }`}</StyledAvailableParagraph>
                  ) : null}
               </StyledTableCell>
               <StyledTableCell>
                  {product.cartWholesaleDTO.formattedNetPrice}{' '}
                  {product.cartWholesaleDTO.formattedConvertedNetPrice &&
                     `(${product.cartWholesaleDTO.formattedConvertedNetPrice})`}
               </StyledTableCell>
               <StyledTableCell>{product.cartWholesaleDTO.taxRate}%</StyledTableCell>
               <StyledTableCell>
                  {product.cartWholesaleDTO.formattedGrossValue}{' '}
                  {product.cartWholesaleDTO.formattedConvertedGrossValue &&
                     `(${product.cartWholesaleDTO.formattedConvertedGrossValue})`}
               </StyledTableCell>
               <StyledTableCell>
                  <Checkbox
                     disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                     onClick={() => handleProductUpdate(product.uuid, undefined, !product.includedProductInOrder)}
                     color="primary"
                     checked={product.includedProductInOrder}
                  />
               </StyledTableCell>
               <StyledTableCell>
                  <StyledDeleteIcon
                     onClick={() =>
                        setModalInfo({
                           modalType: 'DELETE',
                           uuid: product.uuid,
                           removeFromUnavailable: false,
                           index: product.index,
                        })
                     }
                  />
               </StyledTableCell>
               <StyledTableCell>
                  <IconButton
                     onClick={() =>
                        setModalInfo({
                           modalType: 'SWAP',
                           defaultQuantity: product.cartWholesaleDTO.quantity,
                           oldClientPrice:
                              data?.priceType === 'NET'
                                 ? product.cartWholesaleDTO.formattedClientNetPrice
                                 : product.cartWholesaleDTO.formattedClientGrossPrice,
                           uuid: product.productEntityUuid,
                           productToRemoveUuid: product.uuid,
                        })
                     }
                  >
                     <SwapHorizIcon />
                  </IconButton>
               </StyledTableCell>
            </StyledTableRow>
         ))}
      </>
   ) : (
      <TableRow>
         <TableCell colSpan={settings?.userSettings.preferences.showProductNamesInCart === 'true' ? 12 : 11}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  opacity: 0.6,
                  fontWeight: 'bold',
                  color: 'grayText',
               }}
            >
               {t('MAIN_CART:noProducts')}
            </Box>
         </TableCell>
      </TableRow>
   )
}
