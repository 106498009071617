import { pythonHttpClient } from 'shared/lib'
import { FetchNonApiWholesalesResult } from './types'

export function fetchNonApiWholesales(): Promise<FetchNonApiWholesalesResult> {
   return pythonHttpClient
      .get('wholesales/no_api')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch(() => {
         throw new Error('apiErrors.500')
      })
}
