import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import ErrorIcon from '@mui/icons-material/Error'
import Stack from '@mui/material/Stack'

import { Yup } from 'shared/lib'
import InputField from 'shared/components/InputField'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { useCartSummaryQuery } from 'shared/store/useCartSummary'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'
import { useProfileQuery } from 'shared/store/useProfile'

import { useClientPrices } from 'shared/hooks/ClientPriceHook'
import { BuyNowPayload, Modal, ModalFormDefaults, ModalProductDetails, ProductDetails } from '../../types'

const schema = Yup.object().shape({
   cartName: Yup.string().required(),
   clientPrice: Yup.number()
      .required()
      .test({
         name: 'is-proper-value',
         test(value, ctx) {
            const salerPrice = ctx.parent.convertedNetPrice
               ? (ctx.parent.convertedNetPrice as number)
               : (ctx.parent.salerPrice as number)
            if (typeof value === 'number' && value + 0.01 > salerPrice) {
               return true
            }
            return ctx.createError({ message: 'minimumValue', params: { value: salerPrice + 0.01 } })
         },
      }),
   salerPrice: Yup.number().required(),
   quantity: Yup.number()
      .required()
      .test({
         name: 'quantity-in-set',
         test(value, ctx) {
            const quantityInSet = ctx.parent.quantityInSet as number
            if (value === quantityInSet || (typeof value === 'number' && value % quantityInSet === 0)) {
               return true
            }
            return ctx.createError({ message: 'multipleValue', params: { value: quantityInSet } })
         },
      }),
})

interface Props {
   onClose: () => void
   modalData?: Modal
   producer?: PropType<ProductDetails, 'producer'>
   partIndex?: PropType<ProductDetails, 'index'>
   onBuy: (payload: BuyNowPayload) => void
   onAddToCart: (payload: AddToCartPayload) => Promise<{ status: number }>
   isLoading: boolean
}

export default function ModalComponent({
   onClose,
   modalData,
   producer,
   partIndex,
   onBuy,
   onAddToCart,
   isLoading,
}: Props) {
   const { data: profileData } = useProfileQuery()
   const queryClient = useQueryClient()
   const { data: cartData } = useCartSummaryQuery()
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const { clientPricesOn } = useClientPrices()

   const priceType = profileData?.priceType || 'GROSS'
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
   const productDetails: ModalProductDetails = {
      index: partIndex || '',
      producer: producer || '',
      wholesaleName: modalData?.data.wholesaleName || '',
   }

   function handleOnBuy(values: ModalFormDefaults) {
      const payload: BuyNowPayload = {
         cartName: values.cartName,
         netClientPrice:
            priceType === 'NET'
               ? values.clientPrice.toString()
               : (values.clientPrice / (1 + values.taxRate / 100)).toFixed(2).toString(),
         netPrice: values.netPrice.toString(),
         grossPrice: values.grossPrice.toString(),
         currency: values.currency,
         taxRate: values.taxRate,
         quantity: values.quantity,
         wholesaleUuid: modalData?.data.wholesaleUuid as string,
      }
      onBuy(payload)
   }

   function handleOnAddToCart(values: ModalFormDefaults) {
      const payload: AddToCartPayload = {
         netClientPrice:
            priceType === 'NET'
               ? values.clientPrice.toString()
               : (values.clientPrice / (1 + values.taxRate / 100)).toFixed(2).toString(),
         netPrice: values.netPrice.toString(),
         grossPrice: values.grossPrice.toString(),
         currency: values.currency,
         taxRate: values.taxRate,
         quantity: values.quantity,
         wholesaleUuid: modalData?.data.wholesaleUuid as string,
      }
      onAddToCart(payload).then((res) => {
         if (res.status === 200) {
            queryClient.invalidateQueries('mainCart')
            onClose()
         }
      })
   }

   const renderForm = useCallback(() => {
      if (!modalData) {
         return null
      }
      const { data, type } = modalData
      const taxRate = data.taxRate || 23
      const netPrice = data.netPrice || 0
      const convertedNetPrice = data.convertedNetPrice || 0
      const totalAvailability = data.totalAvailability || 0
      const quantityInSet = data.quantityInSet || 1
      const grossPrice = data.grossPrice || 0
      const salerPrice = priceType === 'NET' ? netPrice.toString() : grossPrice.toString()
      const infinityThreshold = data.wholesaleInfiniteAvailabilityThreshold || MAX_PRODUCT_QUANTITY
      const currency = data.currency || 'PLN'
      const clientPrice = priceType === 'NET' ? data.clientNetPrice : data.clientGrossPrice
      const isBuyForm = type === 'BUY'
      const isInCart = cartData?.products.find((item) => item.productEntityUuid === data.productUuid)

      if (type) {
         return (
            <Formik<ModalFormDefaults & { cartName: string }>
               initialValues={{
                  clientPrice,
                  grossPrice,
                  currency,
                  taxRate,
                  salerPrice,
                  convertedNetPrice,
                  netPrice,
                  quantityInSet,
                  quantity: quantityInSet || 1,
                  cartName: dayjs().format('DD-MM-YYYY HH:mm:ss'),
               }}
               validationSchema={schema}
               onSubmit={isBuyForm ? handleOnBuy : handleOnAddToCart}
            >
               <Form>
                  {isBuyForm && (
                     <InputField
                        sx={{ marginBottom: 3 }}
                        variant="standard"
                        fullWidth
                        type="text"
                        name="cartName"
                        label={t('TRANSLATION:columnNames.cartName')}
                     />
                  )}
                  <Stack direction="row" spacing={2} justifyContent="space-between">
                     <div style={{ flex: 1 }}>
                        <div
                           style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingTop: 30,
                           }}
                        >
                           <div style={{ marginBottom: 55 }}>
                              <HiddenWholesalerLogo
                                 wholesalerName={productDetails.wholesaleName}
                                 imgHeight="auto"
                                 imgWidth="160px"
                              />
                           </div>
                           <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                              <div style={{ display: 'flex', gap: 2 }}>
                                 <Typography variant="body1" color="text.secondary">
                                    {`${t('TRANSLATION:columnNames.partIndex')}: `}
                                 </Typography>
                                 <Typography variant="body1">{productDetails.index}</Typography>
                              </div>
                              <div style={{ display: 'flex', gap: 2 }}>
                                 <Typography variant="body1" color="text.secondary">
                                    {`${t('TRANSLATION:columnNames.producer')}: `}
                                 </Typography>
                                 <Typography variant="body1">{productDetails.producer}</Typography>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div style={{ flex: 1 }}>
                        <InputField
                           sx={{ marginBottom: 3, marginTop: 2 }}
                           fullWidth
                           variant="standard"
                           type="Number"
                           name="quantity"
                           label={t('TRANSLATION:columnNames.pickQuantity')}
                           InputProps={{
                              inputProps: {
                                 min: quantityInSet,
                                 max: totalAvailability < infinityThreshold ? totalAvailability : MAX_PRODUCT_QUANTITY,
                                 step: quantityInSet,
                              },
                           }}
                        />
                        {!clientPricesOn && (
                           <InputField
                              sx={{ marginBottom: 3 }}
                              fullWidth
                              variant="standard"
                              name={convertedNetPrice ? 'convertedNetPrice' : 'salerPrice'}
                              label={`${t('TRANSLATION:columnNames.price')} (${convertedNetPrice ? 'EUR' : currency})`}
                              disabled
                           />
                        )}
                        <InputField
                           inputProps={{
                              step: 0.01,
                           }}
                           onFocus={(e) => e.target.select()}
                           fullWidth
                           variant="standard"
                           type="Number"
                           name="clientPrice"
                           label={`${t('PRODUCT_DETAILS:modals.setClientPrice')} (${
                              convertedNetPrice ? 'EUR' : currency
                           })`}
                        />
                        {!isBuyForm && isInCart && (
                           <Typography variant="h6" sx={{ mt: 3 }}>
                              {t(['PRODUCT_DETAILS:modals.alreadyInCart'])}
                           </Typography>
                        )}
                     </div>
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="flex-end" mt={5}>
                     <OutlinedButton variant="outlined" onClick={onClose} disabled={isLoading}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton type="submit" variant="contained" disabled={isLoading}>
                        {isBuyForm ? t('TRANSLATION:order') : t('TRANSLATION:addToCart')}
                     </ContainedButton>
                  </Stack>
               </Form>
            </Formik>
         )
      }
      return null
   }, [modalData, isLoading])

   return (
      <Dialog fullScreen={fullScreen} open={!!modalData} onClose={onClose} aria-labelledby="responsive-dialog-title">
         {modalData ? (
            <>
               <DialogTitle id="responsive-dialog-title">
                  <Typography sx={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: 20 }}>
                     {modalData.type === 'BUY'
                        ? t('PRODUCT_DETAILS:modals.buyNowTitle')
                        : t('PRODUCT_DETAILS:modals.addToCartTitle')}{' '}
                     {modalData.data.quantityInSet !== 1 && (
                        <Tooltip
                           title={
                              t('PRODUCT_DETAILS:modals.multipleMustBeMore', {
                                 quantity: modalData.data.quantityInSet,
                              }) || ''
                           }
                        >
                           <ErrorIcon sx={{ mb: -0.7, color: 'red' }} />
                        </Tooltip>
                     )}
                  </Typography>
               </DialogTitle>
               <DialogContent>{renderForm()}</DialogContent>
            </>
         ) : (
            <DialogContent />
         )}
      </Dialog>
   )
}
