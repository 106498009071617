import { IconButton, MenuItem, Select, TableFooter, TableRow, Typography } from '@mui/material'
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import styled from '@mui/material/styles/styled'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { useQueryClient } from 'react-query'
import Box from '@mui/material/Box'

import { useFilterSavedCarts, useSavedCartsQuery, useSetActiveCartMutation } from 'shared/store/useSavedCarts'
import reverseDateRepresentation from 'shared/utils/helpers/reverseDateRepresentation'
import { StyledContentTableCell } from 'shared/components/StyledTableComponents'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import TableHeadCell from 'shared/components/TableHeadCell'
import { sortByDate } from 'shared/utils/helpers/sorters'
import { useProfileQuery } from 'shared/store/useProfile'

import { ModalInfoType, StatusType } from './types'
import { StyledCloseIcon } from './styles'
import Modal from './components/Modal'
import { pl, en, de } from './locales'

export default function SavedCarts() {
   const { i18n, t } = useTranslation(['TRANSLATION', 'SAVED_CARTS'])
   i18n.addResourceBundle('pl-PL', 'SAVED_CARTS', pl)
   i18n.addResourceBundle('en-EN', 'SAVED_CARTS', en)
   i18n.addResourceBundle('de-DE', 'SAVED_CARTS', de)
   const { mutate } = useSetActiveCartMutation()
   const [selectedFilter, setSelectedFilter] = useState<string>('LAST_90_DAYS')
   const [searchPhrase, setSearchPhrase] = useState<string>()
   const { data, isLoading, error, isError } = useSavedCartsQuery()
   const location = useLocation()
   const { mutate: savedCartsMutate, isLoading: isMutateLoading } = useFilterSavedCarts()
   const queryClient = useQueryClient()
   const navigate = useNavigate()
   const { data: profileData } = useProfileQuery()
   const [modalInfo, setModalInfo] = useState<ModalInfoType>({
      open: false,
      cartName: undefined,
      userName: undefined,
      modalType: undefined,
      uuid: undefined,
   })

   function handleOpen(status: StatusType, userName: string, uuid: string, processingState?: string) {
      if (processingState === 'LOCKED') {
         setModalInfo({ open: true, modalType: 'inProcessing' })
      } else if (status === 'INUSE') {
         setModalInfo({ open: true, modalType: 'alreadyInUse', userName })
      } else if (!status || status === 'FREE' || status === 'MAIN') {
         mutate(uuid, {
            onSuccess: () => {
               navigate('/dashboard/main-cart')
            },
         })
      }
   }

   function returnCartStatus(status: StatusType, processingState: string) {
      if (processingState === 'LOCKED') {
         return 'LOCKED'
      }
      switch (status) {
         case 'MAIN':
         case 'INUSE':
            return status
         default:
            return 'FREE'
      }
   }

   function returnPriceTypeHeader(name: string) {
      return (
         <span>
            {t(`TRANSLATION:columnNames.${name}`)} {t(`TRANSLATION:${profileData?.priceType}`)}
         </span>
      )
   }

   useEffect(() => {
      if (isError) {
         navigate('/dashboard/error-page', {
            state: {
               variant: 'backend',
               path: location.pathname,
               error: {
                  message: error,
                  stack: 'unknown',
               },
            },
         })
      }
   }, [isError, isLoading])

   useEffect(() => {
      queryClient.invalidateQueries('userProfile')
   }, [])

   const columns: MUIDataTableColumn[] = [
      {
         name: 'uuid',
         options: {
            filter: false,
            searchable: false,
            display: 'excluded',
         },
      },
      {
         name: 'name',
         options: {
            customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortThirdClickReset: true,
            filter: false,
            setCellProps: () => ({
               style: { width: 350 },
            }),
         },
      },
      {
         name: 'createdDate',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortCompare: (order: any) => (obj1: { data: string }, obj2: { data: string }) =>
               order === 'asc'
                  ? sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data))
                  : -sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data)),
            setCellProps: () => ({
               style: { width: 100 },
            }),
         },
      },
      {
         name: 'updatedDate',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortCompare: (order: any) => (obj1: { data: string }, obj2: { data: string }) =>
               order === 'asc'
                  ? sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data))
                  : -sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data)),
            setCellProps: () => ({
               style: { width: 100 },
            }),
         },
      },
      {
         name: 'description',
         options: {
            sort: false,
            filter: false,
            setCellProps: () => ({
               style: { width: 400 },
            }),
         },
      },
      {
         name: 'userName',
         options: {
            customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortThirdClickReset: true,
            filter: false,
         },
      },
      {
         name: 'notificationSentDate',
         options: {
            sort: false,
            filter: false,
         },
      },
      {
         name: 'phoneNumber',
         options: {
            sort: false,
            filter: false,
         },
      },
      {
         name: 'formattedValue',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadLabelRender: (value: any) => returnPriceTypeHeader(value.name),
            customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
                  shouldShowPriceType: true,
               }),
         },
      },
      {
         name: 'formattedClientValue',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadLabelRender: (value: any) => returnPriceTypeHeader(value.name),
            customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
                  shouldShowPriceType: true,
               }),
         },
      },
      {
         name: 'status',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            customBodyRender: (value: StatusType, tableMeta: any) =>
               `${t(`SAVED_CARTS:status.${returnCartStatus(value, tableMeta.rowData[8])}`)}`,
         },
      },
      {
         name: 'processingState',
         options: {
            display: 'excluded',
         },
      },
      {
         name: 'removeCart',
         options: {
            sortThirdClickReset: true,
            filter: false,
            sort: false,
            // eslint-disable-next-line react/no-unstable-nested-components
            customBodyRenderLite: (RowIndex: number) => (
               <StyledCloseIcon
                  onClick={(e) => {
                     e.stopPropagation()
                     e.preventDefault()
                     const temp = data?.carts
                        .filter(
                           (item) =>
                              item.processingState === 'AVAILABLE' ||
                              item.processingState === 'LOCKED' ||
                              !item.processingState
                        )
                        .at(RowIndex)
                     setModalInfo({
                        open: true,
                        cartName: temp?.name,
                        uuid: temp?.uuid,
                        modalType: 'delete',
                     })
                  }}
               />
            ),
         },
      },
   ]

   function handleFilter(filter?: string, resetFilters?: boolean) {
      if (resetFilters) {
         setSearchPhrase('')
         setSelectedFilter('LAST_90_DAYS')
         savedCartsMutate('LAST_90_DAYS')
      }
      if (filter) {
         setSelectedFilter(filter)
         savedCartsMutate(filter)
      }
   }

   const translation = t('TRANSLATION:searchAndFilters.searchEverywhere') || ''

   const options: MUIDataTableOptions = {
      responsive: 'simple',
      selectableRows: 'none',
      rowsPerPageOptions: [10, 25, 50],
      rowsPerPage: Number(window.localStorage.getItem('itemsPerPage')) || 25,
      onChangeRowsPerPage: (numberOfRows: number) =>
         window.localStorage.setItem('itemsPerPage', numberOfRows.toString()),
      print: false,
      searchAlwaysOpen: true,
      textLabels: {
         body: {
            noMatch: t('SAVED_CARTS:noResults'),
            columnHeaderTooltip: (column: any) => `${t('SAVED_CARTS:sortBy')} ${column.label}`,
         },
         toolbar: {
            search: t('SAVED_CARTS:search'),
         },
      },
      search: true,
      download: false,
      filter: false,
      viewColumns: false,
      customFooter(
         rowCount: number,
         page: number,
         rowsPerPage: number,
         changeRowsPerPage: (rows: number) => void,
         changePage: (page: number) => void
      ) {
         return (
            <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
               <TableRow>
                  <StyledContentTableCell sx={{ textAlign: 'right', paddingRight: 7 }} colSpan={8}>
                     <Box
                        sx={{
                           width: '100%',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'flex-end',
                        }}
                     >
                        <Typography variant="h6" fontWeight="normal">
                           {t('SAVED_CARTS:rowsPerPage')}
                        </Typography>
                        <Select
                           sx={{ mx: 1, fontSize: 14 }}
                           variant="standard"
                           disabled={data?.carts.length === 0}
                           value={rowsPerPage}
                           onChange={(e) => changeRowsPerPage(Number(e.target.value))}
                        >
                           <MenuItem value={10}> 10 </MenuItem>
                           <MenuItem value={25}> 25 </MenuItem>
                           <MenuItem value={50}> 50 </MenuItem>
                        </Select>
                        <Typography variant="h6" fontWeight="normal">
                           {t('SAVED_CARTS:of', {
                              results: `${rowsPerPage * page + 1}  - ${
                                 rowsPerPage * page + rowsPerPage > data!.carts.length
                                    ? data?.carts.length
                                    : rowsPerPage * page + rowsPerPage
                              }`,
                              resultsCount: rowCount,
                           })}
                        </Typography>
                        <IconButton
                           disabled={data?.carts.length === 0 || page === 0}
                           onClick={() => changePage(page - 1)}
                        >
                           <ChevronLeft />
                        </IconButton>
                        <IconButton
                           disabled={
                              data
                                 ? data.carts.length === 0 || page === Math.ceil(data.carts.length / rowsPerPage) - 1
                                 : false
                           }
                           onClick={() => changePage(page + 1)}
                        >
                           <ChevronRight />
                        </IconButton>
                     </Box>
                  </StyledContentTableCell>
               </TableRow>
            </TableFooter>
         )
      },
      customSearchRender(searchText: string, handleSearch: (search: string) => void) {
         return (
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 2, mt: 2 }}>
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                     variant="standard"
                     value={searchPhrase}
                     label={t('TRANSLATION:searchAndFilters.searchPhrase')}
                     onChange={(e) => {
                        setSearchPhrase(e.target.value)
                        handleSearch(e.target.value)
                     }}
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={selectedFilter === 'ALL'}
                           onChange={() => {
                              if (selectedFilter === 'ALL') {
                                 handleFilter('LAST_90_DAYS')
                              } else {
                                 handleFilter('ALL')
                              }
                           }}
                        />
                     }
                     label={translation}
                  />
               </Box>
               <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <ContainedButton
                     variant="contained"
                     onClick={() => {
                        handleFilter('LAST_90_DAYS', true)
                        handleSearch('')
                     }}
                  >
                     {t('TRANSLATION:searchAndFilters.clearFilters')}
                  </ContainedButton>
                  {data?.years.availableFilters
                     .filter((item) => item !== 'ALL' && item !== 'LAST_7_DAYS')
                     .reverse()
                     .map((item) => (
                        <ContainedButton
                           onClick={() => {
                              handleFilter(item)
                           }}
                           disabled={selectedFilter === item || isMutateLoading}
                           variant="contained"
                           key={item}
                        >
                           {t(`TRANSLATION:searchAndFilters.filters.${item}`)}
                        </ContainedButton>
                     ))}
               </Box>
            </Box>
         )
      },
      fixedHeader: false,
      setRowProps: () => ({ style: { textAlign: 'center', cursor: 'pointer' } }),
      onRowClick: (rowData: any) => {
         const id = rowData[0]
         const cartToOpen = data?.carts
            .filter(
               (item) =>
                  item.processingState === 'AVAILABLE' || item.processingState === 'LOCKED' || !item.processingState
            )
            .find((item) => item.uuid === id)
         if (cartToOpen) handleOpen(cartToOpen.status, cartToOpen.userName, id, cartToOpen.processingState)
      },
   }

   return (
      <Box>
         {data && !isLoading && Array.isArray(data.carts) ? (
            <StyledTableWrapper>
               <MUIDataTable
                  title=""
                  data={data.carts.filter(
                     (item) =>
                        item.processingState === 'AVAILABLE' ||
                        item.processingState === 'LOCKED' ||
                        !item.processingState
                  )}
                  columns={columns.map((el) => ({
                     ...el,
                     key: el.name,
                     label: t(`TRANSLATION:columnNames.${el.name}`),
                  }))}
                  options={options}
               />
            </StyledTableWrapper>
         ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
               <CircularProgress />
            </Box>
         )}
         <Modal
            modalInfo={modalInfo}
            handleClose={() =>
               setModalInfo({
                  ...modalInfo,
                  open: false,
               })
            }
         />
      </Box>
   )
}

const StyledTableWrapper = styled('div')({
   '.MuiTable-root': {
      overflowX: 'scroll',
   },
   '.MuiTable-head': {
      backgroundColor: 'rgba(33,147,224, 0.1)',
   },
   '.MuiTableCell-head': {
      padding: '6px 4px 6px 4px',
      backgroundColor: 'rgba(33,147,224, 0.1)',
      color: 'grayText',
      textAlign: 'center',
   },
   '.MuiTableCell-root': {
      padding: '6px 4px 6px 4px',
      textAlign: 'center',
   },
   '.MuiTableFooter-root': {
      color: 'grayText',
      backgroundColor: 'rgba(33,147,224, 0.1)',
   },
})
