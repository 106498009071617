import Typography from '@mui/material/Typography'
import React, { useState } from 'react'

interface Props {
   filename: string
   producerName: string
   height?: number | string
   width?: number | string
}

export default function ImageComponent({ filename, height, width, producerName }: Props) {
   const [error, setError] = useState(false)
   const imageUrl = `https://files.motorro.eu/img/logos-producers/${filename}.png`

   return error ? (
      <Typography variant="h6">{producerName ? producerName.toUpperCase() : 'NIEZNANY'}</Typography>
   ) : (
      <img
         src={imageUrl}
         style={{
            verticalAlign: 'middle',
            margin: '0 auto',
            display: error ? 'none' : 'block',
            height: height ?? 20,
            width: width ?? 'auto',
         }}
         alt={producerName}
         onError={() => {
            setError(true)
         }}
      />
   )
}
