export const UserTableHeaders: string[] = [
   'partIndex',
   'producer',
   'wholesaler',
   'quantity',
   'netPrice',
   'VAT',
   'grossValue',
   'includeInOrder',
   'removeProduct',
   'swapProduct',
]

export const UserTableHeadersWithProductName: string[] = [
   'partIndex',
   'productName',
   'producer',
   'wholesaler',
   'quantity',
   'netPrice',
   'VAT',
   'grossValue',
   'includeInOrder',
   'removeProduct',
   'swapProduct',
]

export const ClientTableHeaders: string[] = [
   'productName',
   'producer',
   'quantity',
   'netPrice',
   'VAT',
   'grossPrice',
   'grossValue',
   'includeInOrder',
   'removeProduct',
]

export const UnavailableTableHeaders: string[] = [
   'partIndex',
   'producer',
   'wholesaler',
   'quantity',
   'netPrice',
   'VAT',
   'grossValue',
   'removeProduct',
   'swapProduct',
]

export const MobileTableHeaders: string[] = ['productName', 'producer', 'includeInOrder']

export const FullPrintTableHeaders: string[] = [
   'productName',
   'partIndex',
   'producer',
   'quantity',
   'wholesaler',
   'price',
   'clientPrice',
   'value',
   'clientValue',
]

export const ClientPrintTableHeaders: string[] = ['productName', 'producer', 'quantity', 'price', 'value']
