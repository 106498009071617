import React from 'react'
import Card from '@mui/material/Card'
import { useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import ImageComponent from 'shared/components/ImageComponent'
import { Product } from '../../types'
import { BoldTypography, UnitHolder } from '../../styles'
import { getProductName } from '../../helpers'

interface Props {
   handleClose: () => void
   open: boolean
   data?: Product[]
}

export default function UnavailableProductsModal({ handleClose, open, data }: Props) {
   const theme = useTheme()
   const { t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])

   return (
      <Dialog sx={{ textAlign: 'center' }} maxWidth="lg" open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
               {t('ORDER_REFLINK:modals.unavailableProductsTitle')}
            </Typography>
         </DialogTitle>
         <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="body1" color="grayText">
               {t('ORDER_REFLINK:modals.unavailableProductsText')}
            </Typography>

            {data &&
               data.map((product) => (
                  <Card
                     title={product.cartProductName}
                     sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 5,
                        paddingY: 2,
                        paddingX: 3,
                     }}
                  >
                     <BoldTypography>{getProductName(product)}</BoldTypography>
                     <ImageComponent
                        filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                        producerName={product.producerName}
                     />
                     <UnitHolder>
                        <BoldTypography>{product.cartWholesaleDTO.quantity}</BoldTypography>
                        <Typography variant="body2" color="grayText">
                           {`(${t('ORDER_REFLINK:table.pieces')})`}
                        </Typography>
                     </UnitHolder>
                     <UnitHolder>
                        <BoldTypography>{product.cartWholesaleDTO.formattedClientNetPrice}</BoldTypography>
                        <Typography variant="body2" color="grayText">
                           {`(${t('ORDER_REFLINK:table.net')})`}
                        </Typography>
                     </UnitHolder>
                     <UnitHolder>
                        <BoldTypography>{product.cartWholesaleDTO.formattedClientGrossPrice}</BoldTypography>
                        <Typography variant="body2" color="grayText">
                           {`(${t('ORDER_REFLINK:table.gross')})`}
                        </Typography>
                     </UnitHolder>
                     <BoldTypography>23%</BoldTypography>
                     <BoldTypography>{product.cartWholesaleDTO.formattedClientGrossValue}</BoldTypography>
                  </Card>
               ))}
            <Typography variant="body1" color="grayText">
               {t('ORDER_REFLINK:modals.contact')}
            </Typography>
            <OutlinedButton onClick={handleClose} variant="outlined" sx={{ alignSelf: 'center' }}>
               {t('TRANSLATION:understand')}
            </OutlinedButton>
         </DialogContent>
      </Dialog>
   )
}
