import React, { useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import {
   Button,
   FormControl,
   FormControlLabel,
   InputLabel,
   MenuItem,
   Select,
   Switch,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
} from '@mui/material'
import Box from '@mui/material/Box'

import { InfinityThresholdType } from 'views/Authorized/views/Locations/types'
import { CreateReflinkPayload } from 'shared/store/useClientReflinks/types'
import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useAddReflinkMutation } from 'shared/store/useClientReflinks'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { MarkupThresholdType } from 'shared/store/useLocations/types'
import { useLocationsQuery } from 'shared/store/useLocations'
import { useProfileQuery } from 'shared/store/useProfile'
import MuiPhone from 'shared/components/PhoneInput'

interface Props {
   handleClose: () => void
}

type AddReflinkType = {
   phoneNumber: string
   cartName: string
   priceType: PriceType
   instantOrder: boolean
}

export default function AddReflinkModalContent({ handleClose }: Props) {
   const [infinityThresholdValues, setInfinityThresholdValues] = useState<InfinityThresholdType>({
      multiplier: 1,
      addend: 0,
   })
   const [thresholds, setThresholds] = useState<MarkupThresholdType[]>([])
   const { data } = useLocationsQuery()
   const { data: profileData } = useProfileQuery()
   const textFieldRef = useRef<HTMLInputElement>(null)

   const [addReflinkData, setAddReflinkData] = useState<AddReflinkType>({
      phoneNumber: '',
      cartName: '',
      instantOrder: false,
      priceType: profileData?.priceType ?? 'NET',
   })
   const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false)
   const { mutate, isLoading } = useAddReflinkMutation()
   const { t } = useTranslation(['TRANSLATION', 'CLIENTS_CARTS'])

   const priceTypeOptions = [
      { name: t('CLIENTS_CARTS:markupSettings.grossLabel'), value: 'GROSS' },
      { name: t('CLIENTS_CARTS:markupSettings.netLabel'), value: 'NET' },
   ]
   function handleChange(index: number, field: string, value: number) {
      const newThresholds = thresholds.map((item, i) => {
         if (i === index) {
            return { ...item, [field]: value }
         }
         return item
      })

      setThresholds(newThresholds)
   }

   function addThreshold() {
      const lastElement = thresholds[thresholds.length - 1]
      if (lastElement) {
         const newThresholds = [...thresholds]

         if (lastElement.end) {
            newThresholds.push({
               start: Number((lastElement.end + 0.01).toFixed(2)),
               end: Number((lastElement.end + 0.02).toFixed(2)),
               multiplier: 1,
               addend: 1,
            })
         }
         setThresholds(newThresholds)
      } else {
         setThresholds([{ start: 0, end: null, multiplier: 1, addend: 1 }])
      }
   }

   function removeLastItem() {
      const newThresholds = thresholds.slice(0, -1)
      setThresholds(newThresholds)
   }

   function getLastThresholdStartValue() {
      if (thresholds.length > 0) {
         if (thresholds[thresholds.length - 1]) {
            const endValue = thresholds[thresholds.length - 1].end
            if (endValue !== null) {
               return (endValue + 0.01).toFixed(2)
            }
            return ''
         }
      }
      return 0
   }

   function handlePhoneNumber(value: string, country: string) {
      setAddReflinkData({
         ...addReflinkData,
         phoneNumber: value,
      })
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }
   function getLastThresholdEndValue() {
      const lastElement = thresholds[thresholds.length - 1]
      if (lastElement) {
         const { end } = lastElement
         if (end) {
            return (end + 0.01).toFixed(2)
         }
         return 0
      }
      return 0
   }

   function createReflink() {
      const payload: CreateReflinkPayload = {
         ...addReflinkData,
         markupSettings: {
            thresholds: [
               ...thresholds,
               {
                  ...infinityThresholdValues,
                  start: Number(getLastThresholdEndValue()),
                  end: null,
               },
            ],
            priceType: addReflinkData.priceType,
         },
      }
      mutate(payload, {
         onSuccess: () => {
            handleClose()
         },
      })
   }

   useEffect(() => {
      if (data && profileData) {
         setAddReflinkData((prevState) => ({
            ...prevState,
            priceType: profileData.priceType || 'NET',
         }))
         if (data.locations.find((item) => item.locationSettings.uuid === profileData.locationUuid)) {
            const newThresholds = data.locations.find(
               (item) => item.locationSettings.uuid === profileData.locationUuid
            )!.markupSettings.thresholds
            setThresholds(newThresholds ? newThresholds.slice(0, -1) : [])
            setInfinityThresholdValues({
               multiplier: newThresholds[newThresholds.length - 1].multiplier,
               addend: newThresholds[newThresholds.length - 1].addend,
            })
         }
      }
   }, [data])

   useEffect(() => {
      if (textFieldRef.current) {
         textFieldRef.current.focus()
      }
   }, [thresholds.length])

   return (
      <>
         <DialogContent>
            <Box sx={{ px: 1, pt: 1, display: 'flex', flexDirection: 'column', gap: '12px' }}>
               <TextField
                  label={t('TRANSLATION:columnNames.cartName')}
                  onChange={(e) => setAddReflinkData({ ...addReflinkData, cartName: e.target.value })}
               />
               <MuiPhone
                  label={t('CLIENTS_CARTS:clientPhoneNumber')}
                  variant="outlined"
                  value={addReflinkData.phoneNumber}
                  onChange={(value, country) => handlePhoneNumber(value, country)}
               />
               <FormControl fullWidth variant="outlined">
                  <InputLabel>{t('CLIENTS_CARTS:markupSettings.typeOfPriceLabel')}</InputLabel>
                  <Select
                     fullWidth
                     value={addReflinkData.priceType}
                     onChange={(e) =>
                        setAddReflinkData((prevState) => ({ ...prevState, priceType: e.target.value as PriceType }))
                     }
                     label={t('CLIENTS_CARTS:markupSettings.typeOfPriceLabel')}
                  >
                     {priceTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                           {option.name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               <FormControlLabel
                  sx={{ maxWidth: 'fit-content ' }}
                  control={
                     <Switch
                        checked={addReflinkData.instantOrder}
                        onChange={(_, checked) =>
                           setAddReflinkData((prevState) => ({ ...prevState, instantOrder: checked }))
                        }
                     />
                  }
                  label={t('CLIENTS_CARTS:markupSettings.instantOrder')}
               />

               <Box sx={{ maxHeight: 450, overflowY: 'scroll' }}>
                  <Table stickyHeader>
                     <TableHead>
                        <TableRow>
                           <TableCell sx={{ textAlign: 'center' }}>
                              {t('CLIENTS_CARTS:markupSettings.start', {
                                 priceType: t(`TRANSLATION:${profileData?.priceType}`),
                              })}
                           </TableCell>
                           <TableCell sx={{ textAlign: 'center' }}>
                              {t('CLIENTS_CARTS:markupSettings.end', {
                                 priceType: t(`TRANSLATION:${profileData?.priceType}`),
                              })}
                           </TableCell>
                           <TableCell sx={{ textAlign: 'center' }}>
                              {t('CLIENTS_CARTS:markupSettings.multiplier')}
                           </TableCell>
                           <TableCell sx={{ textAlign: 'center' }}>
                              {t('CLIENTS_CARTS:markupSettings.addend')}
                           </TableCell>
                           <TableCell sx={{ textAlign: 'center' }} />
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {thresholds.length > 0 &&
                           thresholds.map((item, index) => {
                              const isLastItem = index === thresholds.length - 1
                              return (
                                 // eslint-disable-next-line react/no-array-index-key
                                 <TableRow key={index}>
                                    <TableCell
                                       sx={{
                                          textAlign: 'center',
                                          '& .MuiTableCell-root': {
                                             px: 3,
                                             py: 0,
                                          },
                                       }}
                                    >
                                       <TextField
                                          sx={{
                                             '& .MuiInputBase-input': {
                                                py: 1,
                                                width: 100,
                                             },
                                          }}
                                          type="Number"
                                          variant="outlined"
                                          disabled
                                          value={item.start}
                                          inputProps={{ min: item.start, step: 0.01 }}
                                       />
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                       <TextField
                                          sx={{
                                             '& .MuiInputBase-input': {
                                                py: 1,
                                                width: 100,
                                             },
                                          }}
                                          type="Number"
                                          inputRef={textFieldRef}
                                          onFocus={(e) => e.target.select()}
                                          variant="outlined"
                                          error={item.end ? item.start > item.end : false}
                                          value={item.end}
                                          onChange={(e) => {
                                             handleChange(index, 'end', Number(e.target.value))
                                          }}
                                          inputProps={{ min: item.start + 0.01, step: 0.01 }}
                                          disabled={!isLastItem}
                                       />
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                       <TextField
                                          sx={{
                                             '& .MuiInputBase-input': {
                                                py: 1,
                                                width: 100,
                                             },
                                          }}
                                          type="Number"
                                          onFocus={(e) => e.target.select()}
                                          variant="outlined"
                                          value={item.multiplier}
                                          onChange={(e) => handleChange(index, 'multiplier', Number(e.target.value))}
                                          inputProps={{ min: 1, step: 0.1 }}
                                       />
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                       <TextField
                                          sx={{
                                             '& .MuiInputBase-input': {
                                                py: 1,
                                                width: 100,
                                             },
                                          }}
                                          type="Number"
                                          onFocus={(e) => e.target.select()}
                                          variant="outlined"
                                          value={item.addend}
                                          onChange={(e) => handleChange(index, 'addend', Number(e.target.value))}
                                          inputProps={{
                                             min: 0,
                                             step: 1,
                                          }}
                                       />
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                       {isLastItem && (
                                          <Button onClick={removeLastItem} variant="outlined" color="error">
                                             {t('TRANSLATION:delete')}
                                          </Button>
                                       )}
                                    </TableCell>
                                 </TableRow>
                              )
                           })}
                        <TableRow>
                           <TableCell
                              sx={{
                                 textAlign: 'center',
                              }}
                           >
                              <TextField
                                 sx={{
                                    '& .MuiInputBase-input': {
                                       py: 1,
                                       width: 100,
                                    },
                                 }}
                                 variant="outlined"
                                 disabled
                                 value={getLastThresholdStartValue()}
                              />
                           </TableCell>
                           <TableCell sx={{ textAlign: 'center' }}>
                              <TextField
                                 variant="outlined"
                                 sx={{
                                    '& .MuiInputBase-input': {
                                       textAlign: 'center',
                                       fontSize: '24px',
                                       paddingY: 0,
                                       width: 100,
                                    },
                                 }}
                                 value="∞"
                                 disabled
                              />
                           </TableCell>
                           <TableCell sx={{ textAlign: 'center' }}>
                              <TextField
                                 sx={{
                                    '& .MuiInputBase-input': {
                                       py: 1,
                                       width: 100,
                                    },
                                 }}
                                 type="Number"
                                 onFocus={(e) => e.target.select()}
                                 variant="outlined"
                                 value={infinityThresholdValues.multiplier}
                                 onChange={(e) =>
                                    setInfinityThresholdValues({
                                       ...infinityThresholdValues,
                                       multiplier: Number(e.target.value),
                                    })
                                 }
                                 inputProps={{ min: 1, step: 0.1 }}
                              />
                           </TableCell>
                           <TableCell sx={{ textAlign: 'center' }}>
                              <TextField
                                 sx={{
                                    '& .MuiInputBase-input': {
                                       py: 1,
                                       width: 100,
                                    },
                                 }}
                                 type="Number"
                                 onFocus={(e) => e.target.select()}
                                 variant="outlined"
                                 value={infinityThresholdValues.addend}
                                 onChange={(e) =>
                                    setInfinityThresholdValues({
                                       ...infinityThresholdValues,
                                       addend: Number(e.target.value),
                                    })
                                 }
                                 inputProps={{ min: 0, step: 1 }}
                              />
                           </TableCell>
                           <TableCell />
                        </TableRow>
                     </TableBody>
                  </Table>
               </Box>
               {thresholds.find((item) => item.end && item.start > item.end) && (
                  <Typography variant="body1" color="error">
                     {t('CLIENTS_CARTS:markupSettings.badEndValue', {
                        thresholdIndex: thresholds.findIndex((item) => item.end && item.start > item.end) + 1,
                     })}
                  </Typography>
               )}
               {thresholds.length === 20 && (
                  <Typography variant="body1" color="error">
                     {t('CLIENTS_CARTS:markupSettings.maxCountOfThresholds')}
                  </Typography>
               )}
               {(thresholds.find((item) => item.multiplier < 1) || infinityThresholdValues.multiplier < 1) && (
                  <Typography variant="body1" color="error">
                     {t('CLIENTS_CARTS:markupSettings.badMultiplierValue')}
                  </Typography>
               )}
               {thresholds.length > 0 && thresholds[thresholds.length - 1].end === null && (
                  <Typography variant="body1" color="error">
                     {t('CLIENTS_CARTS:markupSettings.tooltip')}
                  </Typography>
               )}
            </Box>
            <Box sx={{ textAlign: 'end', py: 1 }}>
               <OutlinedButton
                  disabled={
                     (thresholds.length > 0 && !thresholds[thresholds.length - 1].end) ||
                     thresholds.length === 20 ||
                     (thresholds.length > 0 && !!thresholds.find((item) => item.end && item.start > item.end)) ||
                     (thresholds.length > 0 && !!thresholds.find((item) => item.multiplier < 1))
                  }
                  variant="outlined"
                  onClick={addThreshold}
               >
                  {t('CLIENTS_CARTS:markupSettings.addThreshold')}
               </OutlinedButton>
            </Box>
            <Typography variant="body1"> {t('CLIENTS_CARTS:markupSettings.firstTip')}</Typography>
            <Typography variant="body1"> {t('CLIENTS_CARTS:markupSettings.secondTip')}</Typography>
         </DialogContent>
         <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton
               sx={{ alignSelf: 'center' }}
               variant="contained"
               onClick={createReflink}
               disabled={
                  phoneNumberError ||
                  !addReflinkData.phoneNumber ||
                  isLoading ||
                  (thresholds.length > 0 && !thresholds[thresholds.length - 1].end) ||
                  (thresholds.length > 0 && !!thresholds.find((item) => item.end && item.start > item.end)) ||
                  (thresholds.length > 0 && !!thresholds.find((item) => item.multiplier < 1))
               }
            >
               {t('TRANSLATION:save')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
