import axios, { AxiosError, AxiosHeaders, InternalAxiosRequestConfig } from 'axios'
import { jwtDecode } from 'jwt-decode'

import { JwtTokenData } from 'shared/store/useLogin/types'
import { LocalStorageKeys } from 'shared/consts'
import { loginOut } from 'shared/store/useLogin'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import i18n from 'locales/i18n'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { Base64 } from 'js-base64'

export const authRequest = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
   },
})

export const verificationRequest = axios.create({
   baseURL: process.env.REACT_APP_SMS_URL,
   headers: {
      Accept: 'application/json',
   },
})

export const httpClient = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   headers: {
      Accept: 'application/json',
   },
})

export const pythonHttpClient = axios.create({
   baseURL: process.env.REACT_APP_PYTHON_URL,
   headers: {
      Accept: 'application/json',
   },
})

async function getFingerprint() {
   const fingerPrintFromLs = localStorage.getItem('FINGERPRINT')
   if (fingerPrintFromLs) {
      return fingerPrintFromLs
   }
   const fpPromise = await FingerprintJS.load()
      .then((fp) => fp.get())
      .then((res) => res.visitorId)
   return fpPromise
}

httpClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
   let fingerprint: string | undefined
   try {
      fingerprint = await getFingerprint()
   } catch (error) {
      console.error('Fingerprint error:', error)
   }

   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)

   const headers = new AxiosHeaders(config.headers)

   headers.set('Accept-Language', i18n.language)
   headers.set('X-Fingerprint', fingerprint || '')
   if (token) {
      headers.set('X-Authorization', `Bearer ${token}`)
   }

   return {
      ...config,
      headers,
   }
})

httpClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
   let fingerprint: string | undefined
   try {
      fingerprint = await getFingerprint()
   } catch (error) {
      console.error('Fingerprint error:', error)
   }

   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)

   const headers = new AxiosHeaders(config.headers)

   headers.set('Accept-Language', i18n.language)
   headers.set('X-Fingerprint', fingerprint || '')
   if (token) {
      headers.set('X-Authorization', `Bearer ${token}`)
   }

   return {
      ...config,
      headers,
   }
})

pythonHttpClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
   let fingerprint: string | undefined
   try {
      fingerprint = await getFingerprint()
   } catch (error) {
      console.error('Fingerprint error:', error)
   }

   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)

   const headers = new AxiosHeaders(config.headers)

   headers.set('Accept-Language', i18n.language)
   headers.set('X-Fingerprint', fingerprint || '')
   if (token) {
      headers.set('X-Authorization', `Bearer ${token}`)
   }

   return {
      ...config,
      headers,
   }
})

verificationRequest.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
   let fingerprint: string | undefined
   try {
      fingerprint = await getFingerprint()
   } catch (error) {
      console.error('Fingerprint error:', error)
   }

   const basicAuth = Base64.encode(
      `${process.env.REACT_APP_SECURITY_SMS_USERNAME}:${process.env.REACT_APP_SECURITY_SMS_PASSWORD}`
   )

   const headers = new AxiosHeaders(config.headers)

   headers.set('X-Fingerprint', fingerprint || '')
   headers.set('Authorization', `Basic ${basicAuth}`)

   return {
      ...config,
      headers,
   }
})

let isRefreshing = false

httpClient.interceptors.response.use(
   (response) => {
      return response
   },
   async (error: Error | AxiosError<ErrorType>) => {
      if (axios.isAxiosError(error)) {
         const originalRequest = error.config
         const status = error.response?.status
         if (status === 498 && originalRequest) {
            const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) || ''
            const params = new URLSearchParams()
            params.append('refresh_token', refreshToken)

            if (!isRefreshing) {
               isRefreshing = true

               try {
                  const res = await authRequest.post<{ accessToken: string }>('token/refresh', params)
                  const token = res.data.accessToken
                  const user = jwtDecode<JwtTokenData>(token)
                  const expirationDate = new Date(new Date().getTime() + user.expires * 1000)
                  localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token)
                  localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)

                  if (originalRequest?.headers) {
                     originalRequest.headers.Authorization = `Bearer ${token}`
                  } else {
                     originalRequest.data.headers = { Authorization: `Bearer ${token}` }
                  }

                  return await httpClient(originalRequest)
               } catch (err: any) {
                  if (err.response?.status === 423) {
                     loginOut('/session-end').then(() => {
                        if (isAxiosError<ErrorType>(err)) {
                           switch (err.response?.data.errors[0].code) {
                              case 'errors.authentication.refresh-token-expired':
                                 window.location.href = '/session-end?reason=expired'
                                 break
                              case 'errors.authentication.refresh-token-invalidated':
                                 window.location.href = '/session-end?reason=loggedOut'
                                 break
                              case 'errors.authentication.session-expired':
                                 window.location.href = '/session-end?reason=subscription'
                                 break
                              default:
                                 window.location.href = '/session-end?reason=expired'
                           }
                        }
                     })
                  } else {
                     return Promise.reject(err)
                  }
               } finally {
                  isRefreshing = false
               }
            } else {
               await new Promise<void>((resolve) => {
                  const interval = setInterval(() => {
                     if (!isRefreshing) {
                        clearInterval(interval)
                        resolve()
                     }
                  }, 100)
               })
               return httpClient(originalRequest)
            }
         }
      }
      return Promise.reject(error)
   }
)

pythonHttpClient.interceptors.response.use(
   (response) => {
      return response
   },
   async (error: Error | AxiosError<ErrorType>) => {
      if (axios.isAxiosError(error)) {
         const originalRequest = error.config
         const status = error.response?.status
         if (status === 417 && originalRequest) {
            const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) || ''
            const params = new URLSearchParams()
            params.append('refresh_token', refreshToken)

            if (!isRefreshing) {
               isRefreshing = true

               try {
                  const res = await authRequest.post<{ accessToken: string }>('token/refresh', params)
                  const token = res.data.accessToken
                  const user = jwtDecode<JwtTokenData>(token)
                  const expirationDate = new Date(new Date().getTime() + user.expires * 1000)

                  localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token)
                  localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)

                  if (originalRequest?.headers) {
                     originalRequest.headers.Authorization = `Bearer ${token}`
                  } else {
                     originalRequest.data.headers = { Authorization: `Bearer ${token}` }
                  }

                  return await httpClient(originalRequest)
               } catch (err: any) {
                  if (err.response?.status === 423) {
                     loginOut('/session-end').then(() => {
                        if (isAxiosError<ErrorType>(err)) {
                           switch (err.response?.data.errors[0].code) {
                              case 'errors.authentication.refresh-token-expired':
                                 window.location.href = '/session-end?reason=expired'
                                 break
                              case 'errors.authentication.refresh-token-invalidated':
                                 window.location.href = '/session-end?reason=loggedOut'
                                 break
                              case 'errors.authentication.session-expired':
                                 window.location.href = '/session-end?reason=subscription'
                                 break
                              default:
                                 window.location.href = '/session-end?reason=expired'
                           }
                        }
                     })
                  }
                  return Promise.reject(err)
               } finally {
                  isRefreshing = false
               }
            } else {
               await new Promise<void>((resolve) => {
                  const interval = setInterval(() => {
                     if (!isRefreshing) {
                        clearInterval(interval)
                        resolve()
                     }
                  }, 100)
               })
               return httpClient(originalRequest)
            }
         }
      }
      return Promise.reject(error)
   }
)

// eslint-disable-next-line consistent-return
