import React from 'react'
import { useTranslation } from 'react-i18next'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import {
   StyledTable,
   StyledTableCell,
   StyledTableRow,
   StyledTitleTableCell,
} from 'shared/components/StyledTableComponents'

import ImageComponent from 'shared/components/ImageComponent'
import { QuantityHolder, StyledQuantityButton } from '../../styles'
import { AdditionalService, Product } from '../../types'
import { getProductName } from '../../helpers'

interface Props {
   products: Product[]
   services: AdditionalService[]
   formattedAdditionalServicesGrossValue: string
   formattedAdditionalServicesNetValue: string
   formattedClientGrossValueWithAdditionalServices: string
   formattedClientNetValueWithAdditionalServices: string
   formattedGrossValue: string
   formattedNetValue: string
   handleIncludeInOrder: (cartProductUuid: string, includeProductInOrder: boolean) => void
   handleChangeQuantity: (quantity: number, uuid: string) => void
   disableButtons: boolean
}

export default function DesktopTable({
   products,
   handleChangeQuantity,
   handleIncludeInOrder,
   disableButtons,
   formattedGrossValue,
   formattedNetValue,
   services,
   formattedAdditionalServicesGrossValue,
   formattedAdditionalServicesNetValue,
   formattedClientGrossValueWithAdditionalServices,
   formattedClientNetValueWithAdditionalServices,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])
   return (
      <>
         <StyledTable sx={{ mt: 3, mb: 4 }}>
            <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
               <TableRow>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.productName')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.producer')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.quantity')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.netPrice')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.grossPrice')}</StyledTitleTableCell>
                  <StyledTitleTableCell>VAT</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.grossValue')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.includeInOrder')}</StyledTitleTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {products.map((product) => (
                  <StyledTableRow key={product.uuid}>
                     <StyledTableCell title={product.cartProductName}>{getProductName(product)}</StyledTableCell>
                     <StyledTableCell>
                        <ImageComponent
                           filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                           producerName={product.producerName}
                        />
                     </StyledTableCell>
                     <StyledTableCell>
                        <QuantityHolder>
                           <StyledQuantityButton
                              disabled={product.cartWholesaleDTO.quantity === 0 || disableButtons}
                              onClick={() =>
                                 handleChangeQuantity(
                                    product.cartWholesaleDTO.quantity - product.quantityInSet,
                                    product.uuid
                                 )
                              }
                           >
                              -
                           </StyledQuantityButton>
                           <StyledQuantityButton type="button" style={{ fontWeight: 'normal' }}>
                              {product.cartWholesaleDTO.quantity}
                           </StyledQuantityButton>
                           <StyledQuantityButton
                              disabled={
                                 product.cartWholesaleDTO.quantity === product.cartWholesaleDTO.initialQuantity ||
                                 disableButtons
                              }
                              onClick={() =>
                                 handleChangeQuantity(
                                    product.cartWholesaleDTO.quantity + product.quantityInSet,
                                    product.uuid
                                 )
                              }
                           >
                              +
                           </StyledQuantityButton>
                        </QuantityHolder>
                     </StyledTableCell>
                     <StyledTableCell>{product.cartWholesaleDTO.formattedClientNetPrice}</StyledTableCell>
                     <StyledTableCell>{product.cartWholesaleDTO.formattedClientGrossPrice}</StyledTableCell>
                     <StyledTableCell>{product.cartWholesaleDTO.taxRate}%</StyledTableCell>
                     <StyledTableCell>{product.cartWholesaleDTO.formattedClientGrossValue}</StyledTableCell>
                     <StyledTableCell>
                        <Checkbox
                           disabled={disableButtons || product.cartWholesaleDTO.quantity === 0}
                           color="primary"
                           onClick={() => handleIncludeInOrder(product.uuid, !product.includedProductInOrder)}
                           checked={product.includedProductInOrder}
                        />
                     </StyledTableCell>
                  </StyledTableRow>
               ))}
               <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <StyledTitleTableCell sx={{ color: 'grayText', textAlign: 'center', fontSize: 16 }} colSpan={4}>
                     {`${t('TRANSLATION:columnNames.netSum')}: ${formattedNetValue}`}
                  </StyledTitleTableCell>
                  <StyledTitleTableCell sx={{ color: 'grayText', textAlign: 'center', fontSize: 16 }} colSpan={4}>
                     {`${t('TRANSLATION:columnNames.grossSum')}: ${formattedGrossValue}`}
                  </StyledTitleTableCell>
               </TableRow>
               {services.map((service) => (
                  <StyledTableRow key={service.uuid}>
                     <StyledTableCell colSpan={2}>{service.name}</StyledTableCell>
                     <StyledTableCell>{service.quantity}</StyledTableCell>
                     <StyledTableCell>{service.formattedNetPrice}</StyledTableCell>
                     <StyledTableCell>{service.formattedGrossPrice}</StyledTableCell>
                     <StyledTableCell>{service.taxRate}%</StyledTableCell>
                     <StyledTableCell>{service.formattedGrossValue}</StyledTableCell>
                     <StyledTableCell />
                  </StyledTableRow>
               ))}
               {services.length > 0 && (
                  <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                     <StyledTitleTableCell sx={{ color: 'grayText', textAlign: 'center', fontSize: 16 }} colSpan={4}>
                        {`${t('TRANSLATION:columnNames.netSum')}: ${formattedAdditionalServicesNetValue}`}
                     </StyledTitleTableCell>
                     <StyledTitleTableCell sx={{ color: 'grayText', textAlign: 'center', fontSize: 16 }} colSpan={4}>
                        {`${t('TRANSLATION:columnNames.grossSum')}: ${formattedAdditionalServicesGrossValue}`}
                     </StyledTitleTableCell>
                  </TableRow>
               )}
            </TableBody>
         </StyledTable>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 3 }}>
            <Typography variant="h5">{`${t('TRANSLATION:columnNames.netSum')}: ${formattedClientNetValueWithAdditionalServices}`}</Typography>
            <Typography variant="h5">{`${t('TRANSLATION:columnNames.grossSum')}: ${formattedClientGrossValueWithAdditionalServices}`}</Typography>
         </Box>
      </>
   )
}
